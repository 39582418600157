import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const TemporaryPositionExample = () => {
        const code = `
import threading
import traceback

from flask import Blueprint
from flask import jsonify, request

from database_functions import (
    set_data_by_id,
    get_data_list,
    set_call_data,
    set_call_data_by_id,
    get_call_data,
    get_call_data_list,
    delete_call_data,
    delete_data_by_id, get_call_data_by_id, delete_data_by_key_and_id,
)
from utils import (
    TemporaryRequest,
    wait_for_analysis_response, get_analysis_response,
    normalize_phone_number, get_similarity_results
)

temporary_position_api = Blueprint('temporary_position_api', __name__)

manager_phone = "........."  # הכנס את הטלפון שלך, טלפון  זה ישמש לניהול השירות
email = "........."  # הכנס את האימייל שלך
base_url="........."

# חלק הרישום של השירות
service_data = {
    'manager_phone': manager_phone,
    'email': email,
    'connection_url': base_url + "temporary_position_service",
    'service_name': 'מַעֲרֶכֶת חִפּוּשׂ מְמַלְּאוֹת מָקוֹם',
    'brief_description': 'מַעֲרֶכֶת לְחִפּוּשׂ מְמַלְּאוֹת מָקוֹם בְּמַעֲרֶכֶת הַחִנּוּךְ',
    'message': 'בְּרוּכָה הַבָּאָה לְמַעֲרֶכֶת מְצִיאַת מִלּוּיֵי מָקוֹם. לְהַרְשָׁמָה כִּמְמַלֵּאת מָקוֹם הַקִּישִׁי 1, לְחִפּוּשׂ מְמַלֵּאת מָקוֹם הַקִּישִׁי 2, לִמְחִיקַת פְּרָטַיִךְ מֵהַמַּאֲגָר הַקִּישִׁי 3',
    'audio_url': '',
    'long_explanation': 'מַעֲרֶכֶת לְחִפּוּשׂ מְמַלְּאוֹת מָקוֹם בְּמַעֲרֶכֶת הַחִנּוּךְ אוֹ הַרְשָׁמָה כִּמְמַלֵּאת מָקוֹם',
    'required_data_schema': {},
    'number_of_digits': 1,
    'phone_number_required': True,
    'email_required': False,
    'credit_card_required': False,
    'system_payments': False,
    'external_payments': False,
    'entry_amount_to_be_paid': 0,
    'referral_phone': '',
    'analysis_delay': 0
}


@temporary_position_api.route('/temporary_position_service', methods=['POST'])
def temporary_position_service():
    target = request.json.get('target')
    if target == 'registration':
        return jsonify(service_data), 200
    elif target == 'service_processing':
        try:
            stage = get_call_data('next_stage')
            if not stage:
                stage = 'start'
                phone_number = request.json.get('phone_number')
                if phone_number:
                    set_call_data("phone_number", normalize_phone_number(phone_number))

            return do_stage(stage)
        except Exception as e:
            print(f"Error: {e}")
            traceback.print_exc()
            return send_ending_response()

def do_stage(stage):
    # קבלת השלב מהמילון stages
    stage_data = stages.get(stage)
    if not stage_data:
        print(f"Error: stage '{stage}' not found.")
        return send_ending_response()  # אם השלב לא נמצא, נחזיר תשובה

    next_stage = stage_data.get('next_stage')
    set_call_data('next_stage', next_stage)
    # הפעלת הפעולה של השלב
    stage_data['action']()

    # קבלת השלב הבא מהמידע הגולמי או מהקריאה ל- get_call_data
    next_stage = get_call_data('next_stage')



    # אם אין next_stage, נסיים את הפונקציה
    if not next_stage:
        print(f"Error: next_stage not found for stage {stage}")
        return send_ending_response()

    # בניית התשובה עם הערכים המתאימים
    next_stage_data = stages.get(next_stage)
    if not next_stage_data:
        print(f"Error: next_stage '{next_stage}' not found in stages dictionary.")
        return send_ending_response()


    response = {}

    # בדוק אם כל מפתח קיים לפני הוספתו ל-response
    if 'message' in next_stage_data['object'] and callable(next_stage_data['object']['message']):
        response['message'] = next_stage_data['object']['message']()
    if 'number_of_digits' in next_stage_data['object']:
        response['number_of_digits'] = next_stage_data['object']['number_of_digits']
    if 'required_data_schema' in next_stage_data['object']:
        response['required_data_schema'] = next_stage_data['object']['required_data_schema']

    return jsonify(response), 200



def start_stage():
    digits = request.json.get('digits')
    if digits == '1':
        set_call_data('next_stage', 'registration')
    elif digits == '2':
        set_call_data('next_stage', 'city_input_for_substitute_search')
    elif digits == '3':
        delete_user_data()
        set_call_data('next_stage', 'delete_data')
    else:
        set_call_data('next_stage', 'start')

def delete_user_data():
    """
    מוחק את כל הנתונים הקשורים למשתמש לפי מספר טלפון.
    """
    phone_number = get_call_data('phone_number')
    delete_data_by_key_and_id("substitute", phone_number)  # מחיקת המשתמש מהרשימה הקבועה

def registration_stage():
    set_call_data_by_id("analysis_response_url", "name_and_cities", request.json.get('analysis_response_url'))


def school_option_stage():
    digits = request.json.get('digits')
    if digits == '1':
        set_call_data('school', True)
        set_call_data("next_stage", "subject_input_for_school")
    elif digits != '2':
        set_call_data('next_stage', 'school_option')


def subject_input_for_school_stage():
    set_call_data_by_id("analysis_response_url", "subjects", request.json.get('analysis_response_url'))

def kindergarten_option_stage():
    digits = request.json.get('digits')

    if digits == '1':
        set_call_data('kindergarten', True)
    elif digits != '2':
        set_call_data('next_stage', 'wrong_kindergarten_option')



def special_education_option_stage():
    digits = request.json.get('digits')
    if digits == '1':
        set_call_data('special_education', True)
    elif digits != '2':
        set_call_data('next_stage', 'special_education_option')


def daycare_option_stage():
    digits = request.json.get('digits')
    if digits == '1':
        set_call_data('daycare', True)
    elif digits != '2':

        set_call_data('next_stage', 'daycare_option')

    save_name_and_cities()
    save_subjects()

def confirm_selections_stage():
    digits = request.json.get('digits')
    if digits == '1':
        save_substitute_data()
    else:
        set_call_data('next_stage', 'registration')

def save_name_and_cities():
    name_and_cities_analysis_url = get_call_data_by_id("analysis_response_url", "name_and_cities")
    name_and_cities_analysis = get_analysis_response(name_and_cities_analysis_url)
    set_data_by_id("name_and_cities_analysis", name_and_cities_analysis_url, name_and_cities_analysis)
    set_call_data_by_id("name_and_cities_analysis", name_and_cities_analysis_url, name_and_cities_analysis)
    name = name_and_cities_analysis.get('analysis_response', {}).get('required_data', {}).get('name')
    cities = name_and_cities_analysis.get('analysis_response', {}).get('required_data', {}).get('list_of_cities') or name_and_cities_analysis.get('analysis_response', {}).get('required_data', {}).get('list of cities')
    cities_str = ', '.join(cities) if cities else ''
    set_call_data('name', name)
    set_call_data('cities', cities_str)

def save_subjects():
    subjects_analysis_url = get_call_data_by_id("analysis_response_url", "subjects")
    subject_analysis = get_analysis_response(subjects_analysis_url)
    subjects = subject_analysis.get('analysis_response', {}).get('required_data', {}).get('list_of_subjects_for_lesson')
    set_call_data('subjects', str(subjects))

def get_confirm_message():
    name = get_call_data('name')
    cities = get_call_data('cities')
    kindergarten = get_call_data('kindergarten')
    special_education = get_call_data('special_education')
    daycare = get_call_data('daycare')
    school = get_call_data('school')
    subjects = eval(get_call_data('subjects'))
    subjects_str = ', '.join(subjects) if subjects else ''
    message = (
        f"אָנָּא אַשְּׁרִי אֶת הַפְּרָטִים הַבָּאִים: "
        f"שֵׁם: {name}. "
        f"עָרִים: {cities}. "
        f"מוֹסָדוֹת בָּהֶן אַתְּ מְעֻנְיֶנֶת לַעֲבֹד: "
        f"{'גַּנִּים' if kindergarten else ''} "
        f"{', מוֹסְדוֹת חִנּוּךְ מְיֻחָד' if special_education else ''} "
        f"{', מְעוֹנוֹת' if daycare else ''}"
        f"{', בתי ספר' + (f' במקצועות {subjects_str}' if subjects else '') if school else ''} "
    )
    return message


def save_substitute_data():
    name = get_call_data('name')
    cities = get_call_data('cities')
    kindergarten = get_call_data('kindergarten')
    special_education = get_call_data('special_education')
    daycare = get_call_data('daycare')
    school = get_call_data('school')
    phone_number = get_call_data('phone_number') or "0737373333"
    subjects = get_call_data('subjects')
    data = {
        'name': name,
        'cities': cities,
        'kindergarten': kindergarten,
        'special_education': special_education,
        'daycare': daycare,
        'subjects': subjects,
        'phone_number': phone_number
    }
    set_data_by_id('substitute', phone_number, str(data))


def get_similarity_results_by_keys(data_list, object_to_compare, keys_to_compare, id_key, similarity_threshold=0.6):
    """
    Calculate the similarity between an object and a list of objects based on specified keys.

    This function compares the values of specified keys in 'object_to_compare' with the corresponding values
    in each object within 'data_list'. It calculates the similarity for each key and averages the similarities
    for each object. If the average similarity is above the 'similarity_threshold', the object is included in
    the results, sorted by similarity in descending order.

    Parameters:
    -----------
    data_list : list of dict
        A list of dictionaries where each dictionary represents an object to compare.

    object_to_compare : dict
        A dictionary representing the object to compare against the objects in 'data_list'.

    keys_to_compare : list of str
        A list of keys to compare between 'object_to_compare' and each object in 'data_list'.

    id_key : str
        The key used to uniquely identify each object in 'data_list'.

    similarity_threshold : float, optional (default is 0.6)
        The minimum average similarity required for an object to be included in the results.

    Returns:
    --------
    list of dict
        A list of dictionaries representing the objects from 'data_list' that have an average similarity
        above the 'similarity_threshold', sorted by similarity in descending order. Each dictionary includes
        an additional key 'similarity' representing the average similarity.

    Example:
    --------

    """
    results = []
    for key in keys_to_compare:
        data_list_key = []
        for data_object in data_list:
            data_list_key.append({data_object.get(id_key): str(data_object.get(key))})

        results_key = get_similarity_results(data_list_key, object_to_compare.get(key), similarity_threshold)
        results.append( results_key)

    result = []
    # for each id, calculate the average, and if it is above the similarity_threshold, add it to the results, in order from the high
    for data_object in data_list:
        id = data_object.get(id_key)
        similarity_sum = 0
        for results_key in results:
            for result_key in results_key:
                if result_key.get('id') == id:
                    similarity_sum += result_key.get('similarity')
                    break
        similarity_avg = similarity_sum / len(keys_to_compare)
        if similarity_avg >= similarity_threshold:
            # add the object to result with the average similarity
            result.append({**data_object, 'similarity': similarity_avg})


    # sort the result by the similarity
    return sorted(result, key=lambda x: x['similarity'], reverse=True)





def city_input_for_substitute_search_stage():
    set_call_data_by_id("analysis_response_url", "city", request.json.get('analysis_response_url'))
    create_thread_save_analysis_responses(save_city_analysis_responses_by_thread)


def framework_input_stage():
    digits = request.json.get('digits')
    if digits == '1':
        set_call_data('framework', 'kindergarten')
    elif digits == '2':
        set_call_data('framework', 'daycare')
    elif digits == '3':
        set_call_data('framework', 'special_education')
    elif digits == '4':
        set_call_data('framework', 'teaching')
        set_call_data("next_stage", "subject_input_for_substitute_search")
    else:
        set_call_data('next_stage', 'framework_input_for_substitute_search')
        return

def subject_input_for_substitute_search_stage():
    set_call_data_by_id("analysis_response_url", "subject", request.json.get('analysis_response_url'))
    create_thread_save_analysis_responses(save_subject_analysis_responses_by_thread)

def create_thread_save_analysis_responses(target):
    temp_request = TemporaryRequest(request)
    thread = threading.Thread(target=target, args=(temp_request,))
    thread.start()


def save_city_analysis_responses_by_thread(temp_request):

    city_analysis_url = get_call_data_by_id("analysis_response_url", "city", request=temp_request)
    city_analysis = wait_for_analysis_response(city_analysis_url)
    if city_analysis:
        city = city_analysis.get('analysis_response', {}).get('required_data', {}).get('city')
        set_call_data('city', city, request=temp_request)

    # שמירה שסימנה שהתגובות נשמרו
    set_call_data("city_analysis_responses_saved", "1", request=temp_request)

def save_subject_analysis_responses_by_thread(temp_request):
    subject_analysis_url = get_call_data_by_id("analysis_response_url", "subject", request=temp_request)
    subject_analysis = wait_for_analysis_response(subject_analysis_url)
    if subject_analysis:
        subjects = subject_analysis.get('analysis_response', {}).get('required_data', {}).get('subject')
        set_call_data('subject', subjects, request=temp_request)

    # שמירה שסימנה שהתגובות נשמרו
    set_call_data("subject_analysis_responses_saved", "1", request=temp_request)



def find_matching_substitutes(framework):
    matching_substitutes = []

    substitutes = get_data_list("substitute")

    for substitute in substitutes:

        if type(substitute) is dict:
            substitute_data = list(substitute.values())[0]
            substitute_data = eval(substitute_data)  # Convert string representation of dictionary to actual dictionary

            if framework == 'kindergarten' and substitute_data.get('kindergarten'):
                matching_substitutes.append(substitute_data)
            elif framework == 'daycare' and substitute_data.get('daycare'):
                matching_substitutes.append(substitute_data)
            elif framework == 'special_education' and substitute_data.get('special_education'):
                matching_substitutes.append(substitute_data)
            elif framework == 'teaching':
                matching_substitutes.append(substitute_data)
    return matching_substitutes if matching_substitutes else []



def search_substitute_stage():
    if not get_call_data("subject_analysis_responses_saved") or not get_call_data("city_analysis_responses_saved"):
        set_call_data("next_stage", "search_substitute")
        return


    city = get_call_data('city')
    framework = get_call_data('framework')

    # שליפת רשימת ממלאות מקום מתאימות מהמאגר לפי עיר ומסגרת
    matching_substitutes = find_matching_substitutes(framework)
    object_to_compare = {
        'cities': city,
        'subjects': str(get_call_data('subject'))
    }

    matching_substitutes = get_similarity_results_by_keys(matching_substitutes, object_to_compare, ['cities', 'subjects'], 'phone_number')

    # שמירת הרשימה ב-call_data
    set_call_data("matching_substitutes", str(matching_substitutes))

    # המשך לטיפול בשלב הבא אם יש תוצאות מתאימות
    if len(matching_substitutes) > 0:
        set_call_data("next_stage", "notify_substitutes")
    else:
        set_call_data("next_stage", "no_substitutes_found")


def send_ending_response():
    delete_call_data()
    return jsonify({}), 200  # החזר ריק הוא הסימן לסיום השירות


def get_found_substitutes_message():
    matching_substitutes = get_call_data('matching_substitutes')
    matching_substitutes_arr = eval(matching_substitutes)
    message = "נמצאו ממלאות מקום מתאימות: \n"

    for substitute in matching_substitutes_arr:
        message += f"שם: {substitute.get('name')}, טלפון: {substitute.get('phone_number')}\n"
    return message

def do_nothing():
    pass

stages = {
    'start': {
        'object': {
            'message': lambda: 'בְּרוּכִים הַבָּאִים לְמַעֲרֶכֶת אִתּוּר מַחְלִיפוֹת בְּחִנּוּךְ. לְהַרְשָׁמָה כְּמַחְלִיפָה הַקִּישִׁי 1 לְחִפּוּשׂ מַחְלִיפָה הַקִּישִׁי 2 לִמְחִיקַת פְּרָטַיִךְ הַקִּישִׁי 3',            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': start_stage,
        'next_stage': 'start'
    },
    'registration': {
        'object': {
            'message': lambda: 'אָנָּא אִמְרִי אֶת שְׁמֵךְ וְעָרִים שֶׁאַתְּ מְעֻנְיֶנֶת לַעֲבֹד בָּהֶם',
            'required_data_schema': {'list_of_cities': 'list', 'name': 'str'}
        },
        'action': registration_stage,
        'next_stage': 'school_option'
    },
    'school_option': {
        'object': {
            'message': lambda: 'בַּחֲרִי אֶת כֹּל סוּגֵי הַמִּסְגְּרוֹת שֶׁתִּרְצִי לְשַׁמֵּשׁ כְּמַחְלִיפָה. הַאִם תִּרְצִי לַעֲבֹד בְּבתי סֵפֶר? אִם כֵּן הַקִּישִׁי 1 אִם לֹא הַקִּישִׁי 2',
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': school_option_stage,
        'next_stage': 'kindergarten_option'
    },
    'subject_input_for_school': {
        'object': {
            'message': lambda: 'אִמְרִי אֶת כל המקצועות שאת יכולה ללמד',
            'required_data_schema': {'list_of_subjects_for_lesson': 'list'}
        },
        'action': subject_input_for_school_stage,
        'next_stage': 'kindergarten_option'
    },
    'kindergarten_option': {
        'object': {
            'message': lambda: 'הַאִם תִּרְצִי לַעֲבֹד בְּגַן יְלָדִים? אִם כֵּן הַקִּישִׁי 1 אִם לֹא הַקִּישִׁי 2',
            'number_of_digits': 1,            'required_data_schema': {}
        },
        'action': kindergarten_option_stage,
      'next_stage': 'special_education_option'
    },
    'wrong_kindergarten_option': {
        'object': {
            'message': lambda: 'הִקַּשְׁתְּ עֵרֶךְ לֹא תַּקִּין, הַאִם בִּרְצוֹנֵךְ לַעֲבֹד בְּגַן יְלָדִים? אִם כֵּן הַקִּישִׁי 1 אִם לֹא הַקִּישִׁי 2',            'number_of_digits': 1,
            'required_data_schema': {}
            },
        'action': kindergarten_option_stage,
        'next_stage': 'special_education_option'
    },
    'special_education_option': {
        'object': {
            'message': lambda: 'הַאִם תִּרְצִי לַעֲבֹד בְּמוֹסְדוֹת חִנּוּךְ מְיֻחָד? אִם כֵּן הַקִּישִׁי 1 אִם לֹא הַקִּישִׁי 2',            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': special_education_option_stage,
        'next_stage': 'daycare_option'
    },
    'daycare_option': {
        'object': {
            'message': lambda: 'הַאִם תִּרְצִי לַעֲבֹד בִּמְעוֹנוֹת? אִם כֵּן הַקִּישִׁי 1 אִם לֹא הַקִּישִׁי 2',            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': daycare_option_stage,
        'next_stage': 'confirm_selections'
    },
    'confirm_selections': {
        'object': {
            'message': get_confirm_message,
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': confirm_selections_stage,
        'next_stage': 'successful_registration'
    },
    'successful_registration': {
        'object': {
            'message': lambda: 'הָרִשּׁוּם בֻּצַּע בְּהַצְלָחָה',
        },
        'action': do_nothing,
        'next_stage': 'start'
    },
    'city_input_for_substitute_search': {
        'object': {
            'message': lambda: 'אִמְרִי אֶת הָעִיר שֶׁבָּהּ אַתְּ מְחַפֶּשֶׂת מְמַלֵּאת מָקוֹם',
            'required_data_schema': {'city': 'str'}
        },
        'action': city_input_for_substitute_search_stage,
        'next_stage': 'framework_input_for_substitute_search'
    },
    'framework_input_for_substitute_search': {
        'object': {
             'message': lambda: 'לְחִפּוּשׂ מַחְלִיפָה לְגַן הַקִּישִׁי 1 לְחִפּוּשׂ מַחְלִיפָה לְמָעוֹן הַקִּישִׁי 2 לְחִפּוּשׂ מַחְלִיפָה לְחִנּוּךְ מְיֻחָד הַקִּישִׁי 3 לְחִפּוּשׂ מַחְלִיפָה לְהוֹרָאָה הַקִּישִׁי 4',             'number_of_digits': 1,
             'required_data_schema': {}
        },
        'action': framework_input_stage,
        'next_stage': 'search_substitute'
    },
    'subject_input_for_substitute_search': {
        'object': {
  #          'message': lambda: 'אמרי את המקצוע הנדרש, או הקישי סולמית לחיפוש ממלאת מקום בכל המקצועות',
            'message': lambda: 'אמרי את המקצוע הנדרש ללמד',
            'required_data_schema': {'subject': 'str'}
        },
        'action': subject_input_for_substitute_search_stage,
        'next_stage': 'search_substitute'
    },
    'search_substitute': {
        'object': {
            'message': lambda: 'אָנָּא הַמְתִּינִי בַּזְּמַן שֶׁאָנוּ מְחַפְּשִׂים לָךְ מַחְלִיפָה',
        },
        'action': search_substitute_stage,
     #   'next_stage': 'notify_substitutes'
    },
    'notify_substitutes': {
        'object': {
            'message': get_found_substitutes_message,
        },
        'action': do_nothing,
        'next_stage': 'start'
    },
    'no_substitutes_found': {
        'object': {
            'message': lambda: 'מִצְטַעֲרִים, לֹא נִמְצְאוּ מְמַלְּאוֹת מָקוֹם מַתְאִימוֹת. ,תּוֹדָה וּלְהִתְרָאוֹת.',
        },
        'action': do_nothing,
        'next_stage': 'start'
    },
     'delete_data': {
         'object': {
             'message' : lambda : 'הפעולה בוצעה בהצלחה'
         },
         'action': do_nothing,
         'next_stage': 'start'
     }
}
        `;

    
const codeBlockStyle = {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    };
  return (
    <div dir="ltr" style={codeBlockStyle}>
  <SyntaxHighlighter language="javascript" style={dark} customStyle={{ backgroundColor: '#000', color: '#fff' }} >
  {code}
   </SyntaxHighlighter></div>
);
};

export default TemporaryPositionExample;
