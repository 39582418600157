import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const FreeItemsExample = () => {
        const code = `
import traceback

from flask import Blueprint
from flask import Flask, jsonify, request
import json
import uuid
import threading
from database_functions import (
    set_data,
    set_data_by_id,
    get_data,
    get_data_by_id,
    get_data_list,
    set_call_data,
    set_call_data_by_id,
    get_call_data,
    get_call_data_by_id,
    get_call_data_list,
    delete_call_data,
    delete_data_by_key_and_id,
    delete_data_by_key,
    delete_data_by_id,
)

from utils import (
    TemporaryRequest,
    get_analysis_response,
    wait_for_analysis_response,
    get_similarity_results,
    get_similarity_rate,
    get_next_item,
    normalize_phone_number
)

free_items_api = Blueprint('free_items_api', __name__)

# הקוד פותח על ידי שרה עבער
manager_phone = "089744284"  # הכנס את הטלפון שלך, טלפון  זה ישמש לניהול השירות
email = " sara0556799675@gmail.com"  # הכנס את האימייל שלך
base_url = "........."

# חלק הרישום של השירות
service_data = {
    'manager_phone': manager_phone,
    'email': email,
    'connection_url': base_url + "free_items_service",
    'service_name': 'זירת מעבירים ידיים',
    'brief_description': 'שֵׁרוּת זֶה מְאַפְשֵׁר לַמִּשְׁתַּמְּשִׁים לְהַצִּיעַ חֲפָצִים לִמְסִירָה וְכֵן לִמְצֹא חֲפָצִים שֶׁבִּרְצוֹנָם לְקַבֵּל',
    'message': '  אֲנוּ שְׂמֵחִים לִרְאוֹת אוֹתְךָ בְּזִירַת מַעֲבִירִים יָדַיִם- הַזִּירָה שֶׁתְּאַפְשֵׁר לְךָ לִמְסֹר חֲפָצִים בָּהֶם אֵינְךָ מְעֻנְיָן עוֹד, אוֹ לִמְצֹא חֲפָצִים חֲדָשִׁים שֶׁבִּרְצוֹנְךָ לְקַבֵּל,  לְהַצָּעַת חֵפֶץ לִמְסִירָה וְרִשּׁוּם  לַמַּאֲגָר הַקֵּשׁ 1,לְחִיפּוּשׂ מוּצָר  הַקֵּשׁ 2, לִמְחִיקַת רִשּׁוּמִים קוֹדְמִים הַקֵּשׁ 3 ',
    'audio_url': '',
    'long_explanation': 'הַשֵּׁרוּת מְסַפֵּק לַמִּשְׁתַּמְּשִׁים פְּלַטְפוֹרְמָה נוֹחָה וִיעִילָה לְהַצִּיעַ חֲפָצִים לִמְסִירָה, תּוֹךְ יְצִירַת הִזְדַּמְּנוּיוֹת לְמִחְזוּר וַחֲלֻקָּה שֶׁל פְּרִיטִים שֶׁאֵינָם בְּשִׁמּוּשׁ. הַמִּשְׁתַּמְּשִׁים יְכוֹלִים לְהַעֲלוֹת חֲפָצִים שֶׁהֵם אֵינָם זְקוּקִים לָהֶם יוֹתֵר, כָּךְ שֶׁיִּתְאַפְשֵׁר לַאֲחֵרִים לִמְצֹא וּלְקַבֵּל אוֹתָם, וּבְכָךְ לְקַדֵּם שִׁמּוּשׁ חוֹזֵר וִידִידוּתִי לַסְּבִיבָה. בְּנוֹסָף, הַשֵּׁרוּת מְאַפְשֵׁר לַמִּשְׁתַּמְּשִׁים לְחַפֵּשׂ וּלְהַתְאִים פְּרִיטִים שֶׁהֵם מְעֻנְיָנִים לְקַבֵּל, וּמְסַפֵּק לָהֶם אֶת הָאֶפְשָׁרוּת לְהִתְחַבֵּר עִם אֲנָשִׁים שֶׁמַּצִּיעִים אוֹתָם. הַפְּלַטְפוֹרְמָה מְקַדֶּמֶת סוֹלִידָרִיּוּת וּקְהִלָּתִיּוּת עַל יְדֵי עִידוּד לְמַתַּן וּלְקַבָּלַת חֲפָצִים, וּבְכָךְ תּוֹרֶמֶת לְצִמְצוּם הַצְּרִיכָה הַמְּיֻתֶּרֶת וּמְסַיַּעַת בְּמִחְזוּר פְּרִיטִים בְּאֹפֶן חָכָם וְאַחְרָאִי.',
    'required_data_schema': {},
    'number_of_digits': 1,
    'phone_number_required': True,
    'email_required': False,
    'credit_card_required': False,
    'system_payments': False,
    'external_payments': False,
    'entry_amount_to_be_paid': 0,
    'referral_phone': '',
    'analysis_delay': 0
}


CATEGORIES_SCHEMA = {
    'Furniture': 'Does_the_object_fit_the_category_Furniture?',
    'Electronics': 'Does_the_object_fit_the_category_Electronics',
    'Children_and_Babies': 'Does_the_object_fit_the_category_Children_and_Babies',
    'Clothing_and_Footwear': 'Does_the_object_fit_the_category_Clothing_and_Footwear',
    'Home_and_Garden': 'Does_the_object_fit_the_category_Home_and_Garden',
    'Books_and_Media': 'Does_the_object_fit_the_category_Books_and_Media',
    'Transportation': 'Does_the_object_fit_the_category_Transportation',
    'Sports_and_Leisure': 'Does_the_object_fit_the_category_Sports_and_Leisure',
    'Pets': 'Does_the_object_fit_the_category_Pets',
    'Business_and_Office': 'Does_the_object_fit_the_category_Business_and_Office',
    'Collectibles_and_Art': 'Does_the_object_fit_the_category_Collectibles_and_Art',
    'General': 'Does_the_object_fit_the_category_General',
}


@free_items_api.route('/free_items_service', methods=['POST'])
def free_items_service():
    target = request.json.get('target')
    if target == 'registration':
        return jsonify(service_data), 200
    elif target == 'service_processing':
        try:
            stage = get_call_data('next_stage')
            if not stage:
                stage = 'start'
                phone_number = request.json.get('phone_number')
                if phone_number:
                    set_call_data("phone_number", phone_number)

            return do_stage(stage)
        except Exception as e:
            return send_ending_response()


# זו הפונקציה המעודכנת:
def do_stage(stage):
    # קבלת השלב מהמילון stages
    stage_data = stages.get(stage)
    if not stage_data:
        return send_ending_response()  # אם השלב לא נמצא, נחזיר תשובה

    next_stage = stage_data.get('next_stage')
    set_call_data('next_stage', next_stage)
    # הפעלת הפעולה של השלב
    stage_data['action']()

    # קבלת השלב הבא מהמידע הגולמי או מהקריאה ל- get_call_data
    next_stage = get_call_data('next_stage')

    # אם אין next_stage, נסיים את הפונקציה
    if not next_stage:
        return send_ending_response()

    # בניית התשובה עם הערכים המתאימים
    next_stage_data = stages.get(next_stage)
    if not next_stage_data:
        return send_ending_response()

    response = {}

    # בדוק אם כל מפתח קיים לפני הוספתו ל-response
    if 'message' in next_stage_data['object'] and callable(next_stage_data['object']['message']):
        response['message'] = next_stage_data['object']['message']()
    if 'number_of_digits' in next_stage_data['object']:
        response['number_of_digits'] = next_stage_data['object']['number_of_digits']
    if 'required_data_schema' in next_stage_data['object']:
        response['required_data_schema'] = next_stage_data['object']['required_data_schema']

    return jsonify(response), 200


def start_stage():
    """
        Handles the initial stage based on user input.

        Request JSON Structure:
        {
            "digits":   # The input from the user, expected as a single digit
        }

        Behavior:
        - If digits are '1', sets the next stage to 'product_registration_for_delivery'.
        - If digits are '2', sets the next stage to 'finding_object_for_acceptance'.
        - If digits are '3', sets the next stage to 'delete_items'.
        - For any other input, resets to the 'start' stage.

        Returns:
            None
        """
    digits = request.json.get('digits')
    if not digits:
        return
    if digits == '1':
        set_call_data('next_stage', 'product_registration_for_delivery')
    elif digits == '2':
        set_call_data('next_stage', 'finding_object_for_acceptance')
    elif digits == '3':
        set_call_data('next_stage', 'delete_items')
    else:
        set_call_data('next_stage', 'start')


def finish_and_return_stage():
    """
        Handles the stage where the process either ends or returns to the start.

        Request JSON Structure:
        {
            "digits":   # The input from the user, expected as '0' or None
        }

        Behavior:
        - If digits are '0', sets the next stage to 'start'.
        - For any other input, sends an ending response.

        Returns:
            None
        """
    digits = request.json.get('digits')
    if not digits:
        send_ending_response()
        # elif digits == '0':
        set_call_data('next_stage', 'start')
    else:
        set_call_data('next_stage', 'start')


def product_registration_for_delivery_stage():
    """
        Initiates the process of registering a product for delivery.

        Request JSON Structure:
        {
            "analysis_response_url": str  # URL for analysis response of item data
        }

        Behavior:
        - Sets the analysis response URL for 'username_and_name_and_description_of_item'.
        - Launches a threaded process to save and analyze responses for product registration.

        Returns:
            None
        """
    set_call_data_by_id("analysis_response_url", "username_and_name_and_description_of_item",
                        request.json.get('analysis_response_url'))
    save_and_continue_stage(save_analysis_responses_by_thread_product_registration)


def save_analysis_responses_by_thread_product_registration(temp_request):
    """
       Saves analysis responses for product registration.

       Args:
           temp_request: TemporaryRequest object containing the current request context.

       Behavior:
       - Retrieves analysis responses and processes data for username, object name, description, and categories.
       - Sets matched categories in call data.

       Returns:
           None
       """
    response_urls_list = get_call_data_list("analysis_response_url", request=temp_request)
    response_urls_dict = {}
    for response in response_urls_list:
        response_urls_dict.update(response)
    username = get_call_data('username', request=temp_request)
    object_name = get_call_data('object_name', request=temp_request)
    Description = get_call_data('Description_of_the_unique_characteristics',
                                request=temp_request)
    city_or_neighborhood = get_call_data('city_or_neighborhood', request=temp_request)

    # קריאה לפונקציה categorize_object
    # matched_categories = categorize_object(temp_request)

    if not username and not object_name and not Description and not city_or_neighborhood:
        name_and_description_analysis = wait_for_analysis_response(
            response_urls_dict['username_and_name_and_description_of_item'])
        if name_and_description_analysis:
            set_call_data('username',
                          name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              "username"),
                          request=temp_request)
        set_call_data('object_name',
                      name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          "object_name"),
                      request=temp_request)
        set_call_data('Description',
                      name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          "Description_of_the_unique_characteristics"), request=temp_request)
        set_call_data('city_or_neighborhood',
                      name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          "city_or_neighborhood"),
                      request=temp_request)
    # שמירת הקטגוריות ברשימה או התנהגות אחרת
    # set_call_data('Matched_Categories', matched_categories, request=temp_request)
    set_call_data("analysis_response_product_saved", "1", request=temp_request)


# לא בשימוש כרגע
def location_collection_options_limitations_stage():
    set_call_data_by_id("analysis_response_url", "collection_options",
                        request.json.get('analysis_response_url'))
    save_and_continue_stage(save_analysis_responses_by_thread_collection_options)


# לא בשימוש כרגע
def save_analysis_responses_by_thread_collection_options(temp_request):
    response_urls_list = get_call_data_list("analysis_response_url", request=temp_request)
    response_urls_dict = {}
    for response in response_urls_list:
        response_urls_dict.update(response)
    city_or_neighborhood = get_call_data('city_or_neighborhood', request=temp_request)
    collection_option = get_call_data('Possibility_of_self_collection_or_delivery', request=temp_request)
    spacial_conditions = get_call_data('Availability_for_collection_or_special_conditions_for_receiving_the_item',
                                       request=temp_request)
    if not city_or_neighborhood and not collection_option and not spacial_conditions:
        collection_options_analysis = wait_for_analysis_response(response_urls_dict['collection_options'])
        if collection_options_analysis:
            set_call_data('city_or_neighborhood',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              "city_or_neighborhood"),
                          request=temp_request)
            set_call_data('collection_option',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              'Possibility_of_self_collection_or_delivery'),
                          request=temp_request)
            set_call_data('spacial_conditions',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              'Availability_for_collection_or_special_conditions_for_receiving_the_item'),
                          request=temp_request)

    set_call_data("analysis_response_collection_saved", "1", request=temp_request)


def state_of_item_stage():
    """
        Handles the item state selection stage based on user input.

        Retrieves the user's input (digits) from the request JSON and updates the call data
        with the corresponding state. If the input is invalid, it sets the next stage to 'state_of_item'.

        Input:
            digits (str): The user's selection, where:
                - '1' = New
                - '2' = Used
                - '3' = Needs Repair
                - '4' = All items

        Output:
            Updates call data with the selected state or loops back to the current stage.
        """
    digits = request.json.get('digits')
    if digits and digits == '1':
        set_call_data("state", "חָדָשׁ")
    elif digits == '2':
        set_call_data("state", "מְשֻׁמָּשׁ")
    elif digits == '3':
        set_call_data("state", "זָקוּק לְתִקּוּן")
    elif digits == '4':
        set_call_data('state', 'all')
    else:
        set_call_data('next_stage', 'state_of_item')


def quantity_selection_stage():
    """
       Manages the quantity selection process during user interaction.

       Retrieves the quantity from the request JSON, validates it as a digit, and
       saves it to the call data. If the input is invalid, it loops back to this stage.

       Input:
           quantity (str): The user's input representing the quantity of items.

       Output:
           Updates call data with the selected quantity or loops back to the current stage.
       """
    quantity = request.json.get('digits')
    if quantity.isdigit():
        set_call_data("quantity", int(quantity))  # שמירה ב-call data
    else:
        set_call_data('next_stage', 'quantity_selection')


def save_and_continue_stage(target):
    """
        Executes the specified target function in a separate thread.

        Parameters:
            target (callable): The function to be executed in a separate thread.
        """
    temp_request = TemporaryRequest(request)
    thread = threading.Thread(target=target, args=(temp_request,))
    thread.start()


def finding_object_for_acceptance_stage():
    """
       Prepares the system for item acceptance by saving the analysis response URL and
       initiating data analysis in a separate thread.

       Input:
           analysis_response_url (str): The URL for item analysis from the request JSON.

       Output:
           Triggers the data analysis process for item acceptance.
       """
    set_call_data_by_id("analysis_response_url", "item_for_acceptance",
                        request.json.get('analysis_response_url'))
    save_and_continue_stage(save_analysis_responses_by_thread_object_acceptance)


def save_analysis_responses_by_thread_object_acceptance(temp_request):
    """
      Processes analysis responses for item acceptance in a separate thread.

      This function aggregates analysis response URLs, retrieves relevant data
      for object name and location if missing, and saves the analysis response data.

      Parameters:
          temp_request (TemporaryRequest): A temporary request object for thread-safe data handling.

      Behavior:
          - Fetches analysis response URLs.
          - If 'object_name' or 'city_or_neighborhood' are missing, retrieves them from analysis responses.
          - Marks the analysis response as saved.
      """
    response_urls_list = get_call_data_list("analysis_response_url", request=temp_request)
    response_urls_dict = {}
    for response in response_urls_list:
        response_urls_dict.update(response)
    object_name = get_call_data('object_name', request=temp_request)
    city_or_neighborhood = get_call_data('city_or_neighborhood', request=temp_request)
    if not city_or_neighborhood and not object_name:
        collection_options_analysis = wait_for_analysis_response(response_urls_dict['item_for_acceptance'])
        if collection_options_analysis:
            set_call_data('object_name',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              "object_name"),
                          request=temp_request)
            set_call_data('city_or_neighborhood',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              "city_or_neighborhood"),
                          request=temp_request)
    set_call_data("analysis_response_object_acceptance_saved", "1", request=temp_request)


def filter_item_by_location_stage():
    """
        Filters items based on location data provided in the request.

        Behavior:
            - Retrieves the 'analysis_response_url' from the request JSON.
            - Saves it under the 'location_acceptance' key.
        """
    set_call_data_by_id("analysis_response_url", "location_acceptance",
                        request.json.get('analysis_response_url'))


def create_free_item_data(object_name,
                          city_or_neighborhood, state):
    """
        Creates data for a free item based on provided details.

        Parameters:
            object_name (str): The name of the item.
            city_or_neighborhood (str): The item's location.
            state (str): The item's condition (e.g., new, used, needs repair).

        Returns:
            dict: A dictionary containing the free item's data, including a unique identifier.
        """

    # יצירת מזהה ייחודי
    free_item = str(uuid.uuid4())
    # יצירת אובייקט נתונים
    free_item_data = {
        "free_item": free_item,
        "object_name": object_name,
        "city_or_neighborhood": city_or_neighborhood,
        "state": state,
    }

    return free_item_data


def process_free_item(obj):
    """
        Retrieves the full data of a free item by its ID.

        Parameters:
            obj (dict): A dictionary where the key is the item's ID.

        Returns:
            dict: The complete data of the free item.
        """
    # שליפת ה-ID
    item_id = list(obj.keys())[0]
    # קבלת האובייקט המלא מהפונקציה
    free_item_data = get_data_list(item_id)

    return free_item_data  # מחזירה את האובייקט המלא


def merge_free_item_data(free_item_data_list):
    """
       Merges a list of free item dictionaries into a single list of combined data.

       Parameters:
           free_item_data_list (list): A list of dictionaries containing free item data.

       Returns:
           list: A list of combined dictionaries representing merged item data.
       """
    result = []

    for free_item in free_item_data_list:
        # מיזוג כל המילונים בפריט לאובייקט אחד
        combined_data = {}
        for data in free_item:
            combined_data.update(data)

        # הוספת האובייקט המאוחד לתוצאה
        result.append(combined_data)

    return result


def search_item_stage():
    """
        Handles the search stage for free items based on user preferences.

        Behavior:
            - Ensures the analysis response has been saved.
            - Collects user preferences (name, location, condition).
            - Searches for matching items using similarity analysis.
            - Saves matching items and their details to the call data.
            - Sets up tracking for the current index and total count of matches.
        """
    if not get_call_data("analysis_response_object_acceptance_saved"):
        set_call_data("next_stage", "search_items")
        return
    object_name = get_call_data('object_name')
    city_or_neighborhood = get_call_data('city_or_neighborhood')
    state = get_call_data('state')
    # שליפת רשימת חפצים לפי עיר, מצב ושם אם אין, נשלוף לפי הקטגוריות

    free_item_ob = create_free_item_data(object_name, city_or_neighborhood, state)
    free_items_list = get_data_list("free_item")
    # שימוש ב-map
    # כעת, נעשה שימוש ב-map כך שיתקבל מערך של אובייקטים
    free_item_data_list = list(map(process_free_item, free_items_list))
    merged_data = merge_free_item_data(free_item_data_list)
    keys_to_compare = ["object_name", "city_or_neighborhood", "state"]

    map_data_list = get_similarity_results_by_keys(merged_data, free_item_ob, keys_to_compare, 'free_item')
    for index, data in enumerate(map_data_list):
        free_item = data.get("free_item")  # שליפת ה-ID של החפץ
        set_call_data_by_id("relevant_free_items", index, free_item)  # שמירת כל המידע תחת ה-ID
        # בדוק אם הנתונים נשמרים
        saved_data = get_call_data_by_id("relevant_free_items", index)
    get_call_data_by_id("relevant_free_items", 0)

    set_call_data("current_index", 0)  # לשמור איפה אני נמצאת בלולאה
    set_call_data("count_free_items", len(map_data_list))  # לכמה יש לי סהכ הכל


def get_similarity_results_by_keys(data_list, object_to_compare, keys_to_compare, id_key, similarity_threshold=0.6):
    """
    Calculate the similarity between an object and a list of objects based on specified keys.

    This function compares the values of specified keys in 'object_to_compare' with the corresponding values
    in each object within 'data_list'. It calculates the similarity for each key and averages the similarities
    for each object. If the average similarity is above the 'similarity_threshold', the object is included in
    the results, sorted by similarity in descending order.

    Parameters:
    -----------
    data_list : list of dict
        A list of dictionaries where each dictionary represents an object to compare.

    object_to_compare : dict
        A dictionary representing the object to compare against the objects in 'data_list'.

    keys_to_compare : list of str
        A list of keys to compare between 'object_to_compare' and each object in 'data_list'.

    id_key : str
        The key used to uniquely identify each object in 'data_list'.

    similarity_threshold : float, optional (default is 0.6)
        The minimum average similarity required for an object to be included in the results.

    Returns:
    --------
    list of dict
        A list of dictionaries representing the objects from 'data_list' that have an average similarity
        above the 'similarity_threshold', sorted by similarity in descending order. Each dictionary includes
        an additional key 'similarity' representing the average similarity.

    Example:
    --------

    """
    results = []
    for key in keys_to_compare:
        data_list_key = []
        for data_object in data_list:
            data_list_key.append({data_object.get(id_key): str(data_object.get(key))})

        results_key = get_similarity_results(data_list_key, object_to_compare.get(key), similarity_threshold)
        results.append(results_key)

    result = []
    # for each id, calculate the average, and if it is above the similarity_threshold, add it to the results, in order from the high
    for data_object in data_list:
        id = data_object.get(id_key)
        similarity_sum = 0
        for results_key in results:
            for result_key in results_key:
                if result_key.get('id') == id:
                    similarity_sum += result_key.get('similarity')
                    break
        similarity_avg = similarity_sum / len(keys_to_compare)
        if similarity_avg >= similarity_threshold:
            # add the object to result with the average similarity
            result.append({**data_object, 'similarity': similarity_avg})

    # sort the result by the similarity
    return sorted(result, key=lambda x: x['similarity'], reverse=True)


def get_found_items_message():

    """יוצר הודעה מבוססת פרטי החפץ"""

    # שליפת ה-ID האחרון שהוצג
    index = get_call_data('current_index')

    # שליפת המידע הרלוונטי על הטרמפ לפי ה-ID
    relevant_free_items = get_call_data_by_id('relevant_free_items', index)
    relevant_free_items_list = get_data_list(relevant_free_items)

    # Assuming relevant_free_items is a list of dictionaries
    if relevant_free_items_list and isinstance(relevant_free_items_list, list):
        object_name = relevant_free_items_list[3].get('object_name', '')
        state = relevant_free_items_list[6].get('state', 'לא צוין')
        city_or_neighborhood = relevant_free_items_list[1].get('city_or_neighborhood', 'לא צוין')
        username = relevant_free_items_list[7].get('username', 'לא צוין')
        phone_number = relevant_free_items_list[4].get('phone_number', 'לא צוין')
        phone_number = normalize_phone_number(phone_number)
        free_item_message = (
            f"נמצא חפץ {object_name}:"
            f"מצבו: {state}"
            f"ניתן לקחת מ: {city_or_neighborhood}"
            f"שם הבעלים: {username}"
            f"טלפון: {phone_number}"
            f" לחץ 1 לִמְעַבָּר לחפץ הַבָּא, אוֹ 2 לשמיעת מִסְפַּר הטֶלֶפוֹן שנית."
        )
        return free_item_message
    else:
        set_call_data('next_stage', 'no_items_found')
        return "אין יותר חפצים מתאימים. אפשר לנסות מאוחר יותר!"


def notify_items_stage():
    digits = request.json.get('digits')

    # שליפת ה-index הנוכחי
    index = int(get_call_data('current_index'))
    count_free_items = int(get_call_data("count_free_items"))
    # שליפת כמות הטרמפים

    # טיפול במקרה שאין טרמפים מתאימים
    if index == count_free_items - 1:
        set_call_data("next_stage", "no_items_found")
        return

    relevant_free_items = get_call_data_by_id('relevant_free_items', index)

    # טיפול במעבר בין שלבים
    if digits == "1":  # מעבר לחפץ הבא
        set_call_data('current_index', index + 1)

        set_call_data("next_stage", "notify_items")
        return
    elif digits == "2":  # מעבר לקבלת מספר טלפון
        set_call_data('current_index', index + 1)
        set_call_data("next_stage", "handle_phone_request")
    else:
        set_call_data("next_stage", "no_items_found")


def handle_phone_request():
    """מטפל בבקשת מספר טלפון."""
    index = get_call_data('current_index')  # מזהה הטרמפ האחרון שהוצג
    relevant_free_items = get_call_data_by_id('relevant_free_items', index)
    relevant_free_items_list = get_data_list(relevant_free_items)
    object_name = relevant_free_items_list[1].get('object_name', '')
    username = relevant_free_items_list[3].get('username', 'לא צוין שם')
    phone_number = relevant_free_items_list[4].get('phone_number', 'לא נשמר טלפון')
    phone_number = normalize_phone_number(phone_number)

    return (
        f": החפץ{object_name}.נמצא אצל:{username} מִסְפַּר הַטֶּלֶפוֹן שלו הוא: {phone_number}. להמשך חיפוש לחץ 1, לחזרה לתפריט ראשי לחץ 2 . "
    )


def handle_phone_request_stage():
    digits = request.json.get('digits')
    if digits and digits == '1':
        set_call_data('next_stage', 'notify_items')
    elif digits == '2':
        set_call_data('next_stage', 'start')
    else:
        send_ending_response()


def no_items_found_stage():
    digits = request.json.get('digits')
    if digits and digits == '1':
        set_call_data('next_stage', 'finding_object_for_acceptance')
    elif digits == '2':
        set_call_data('next_stage', 'start')
    else:
        send_ending_response()


def save_analysis_responses_by_thread(temp_request):
    """Save the information of a delivery object"""
    response_urls_list = get_call_data_list("analysis_response_url", request=temp_request)
    response_urls_dict = {}
    for response in response_urls_list:
        response_urls_dict.update(response)
    username = get_call_data('username', request=temp_request)
    object_name = get_call_data('object_name', request=temp_request)
    Description = get_call_data('Description_of_the_unique_characteristics',
                                request=temp_request)
    # קריאה לפונקציה categorize_object
    matched_categories = categorize_object(temp_request)

    # שמירת הקטגוריות ברשימה או התנהגות אחרת
    set_call_data('Matched_Categories', matched_categories, request=temp_request)

    city_or_neighborhood = get_call_data('city_or_neighborhood', request=temp_request)
    collection_option = get_call_data('Possibility_of_self_collection_or_delivery', request=temp_request)
    spacial_conditions = get_call_data('Availability_for_collection_or_special_conditions_for_receiving_the_item',
                                       request=temp_request)
    # state=get_call_data('state',request=temp_request)
    # quantity=get_call_data('quantity',request=temp_request)
    if not username and not object_name and not Description:
        name_and_description_analysis = wait_for_analysis_response(
            response_urls_dict['username_and_name_and_description_of_item'])
        if name_and_description_analysis:
            set_call_data('username',
                          name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              "username"),
                          request=temp_request)
        set_call_data('object_name',
                      name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          "object_name"),
                      request=temp_request)
        set_call_data('Description',
                      name_and_description_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          "Description_of_the_unique_characteristics"), request=temp_request)
    if not city_or_neighborhood and not collection_option and not spacial_conditions:
        collection_options_analysis = wait_for_analysis_response(response_urls_dict['collection_options'])
        if collection_options_analysis:
            set_call_data('city_or_neighborhood',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              "city_or_neighborhood"),
                          request=temp_request)
            set_call_data('collection_option',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              'Possibility_of_self_collection_or_delivery'),
                          request=temp_request)
            set_call_data('spacial_conditions',
                          collection_options_analysis.get('analysis_response', {}).get('required_data', {}).get(
                              'Availability_for_collection_or_special_conditions_for_receiving_the_item'),
                          request=temp_request)

    set_call_data("analysis_responses_saved", "1", request=temp_request)


def categorize_object(temp_request):
    """Save all the matching categories about the item"""
    matched_categories = []
    for category_name, schema_key in CATEGORIES_SCHEMA.items():
        category_fit = get_call_data(schema_key, request=temp_request)
        if category_fit:
            matched_categories.append(category_name)
    set_call_data('Matched_Categories', matched_categories, request=temp_request)
    return matched_categories


def save_items_stage():
    """
        Handles the saving of items in the system.

        Description:
            This function validates the completeness of user-submitted item details and either saves the data
            or prompts the user to re-enter missing fields. If all data is valid, the function generates a unique ID
            for the item, stores it in the system, and associates it with the user's phone number.

        Behavior:
            - Checks if the analysis response has been saved ('analysis_response_product_saved').
            - Retrieves the following fields from the call data:
                - 'phone_number': User's contact number.
                - 'username': User's name.
                - 'object_name': The name of the item.
                - 'description': A description of the item.
                - 'city_or_neighborhood': The location of the item.
                - 'state': The condition of the item (e.g., new, used, needs repair).
                - 'quantity': The number of items.
            - Identifies and lists any missing fields.
            - If fields are missing:
                - Resets the call data, retaining the phone number.
                - Sets the next stage to prompt the user to re-enter details.
                - Sends a message requesting the user to restart the process.
            - If all fields are valid:
                - Generates a unique 'item_id'.
                - Saves the item and its details in the database, associating it with the phone number.
                - Sets the next stage to continue the process.

        Returns:
            None: Updates the call data and the database with item information or prompts for missing details.
        """
    if get_call_data("analysis_response_product_saved"):
        phone_number = get_call_data("phone_number")
        username = get_call_data("username")
        object_name = get_call_data('object_name')
        Description = get_call_data('Description')
        city_or_neighborhood = get_call_data('city_or_neighborhood')
        # collection_option= get_call_data('collection_option')
        # spacial_conditions = get_call_data('spacial_conditions')
        state = get_call_data('state')
        quantity = get_call_data('quantity')
        # matched_categories = get_call_data('matched_categories')
        fields = {
            'username': username,
            'object_name': object_name,
            'description': Description,
            'city_or_neighborhood': city_or_neighborhood,
            # 'collection_option': collection_option,
            # 'spacial_conditions': spacial_conditions,
            'state': state,
            'quantity': quantity,
            # 'matched_categories': matched_categories
        }

        # יצירת רשימה של שמות השדות שהם ריקים או None
        empty_fields = [field for field, value in fields.items() if value in [None, ""]]

        if empty_fields:
            # דורש שינוי - כאן ממש מומלץ להחזיר דרישה חדשה רק עבור מה שחסר
            # set_call_data('next_stage',"")
            # set_call_data("analysis_responses_saved","")
            phone_number = get_call_data("phone_number")
            delete_call_data()
            set_call_data("phone_number", phone_number)
            set_call_data('next_stage', 'product_registration_for_delivery')
            massage = "חסרים לי נתונים, אנא חזור על התהליך והשתדל למלא את כֹּל הפרטים, לְחַץ 1 להתחלה"

        else:
            item_id = str(uuid.uuid4())
            set_data_by_id(item_id, 'free_item', item_id)
            set_data_by_id('free_item', item_id, item_id)
            # שמירה כדי שנוכל להוציא נתונים לפי טלפון
            set_data_by_id('phone_number', item_id, phone_number)
            # שמירה לפי item_id כדי שנוכל להוציא את כל הפריטים
            set_data_by_id(item_id, "phone_number", phone_number)
            set_data_by_id(item_id, "username", username)
            set_data_by_id(item_id, "object_name", object_name)
            set_data_by_id(item_id, "Description", Description)
            # set_data_by_id("matched_categories", item_id, matched_categories)
            set_data_by_id(item_id, "city_or_neighborhood", city_or_neighborhood)
            # set_call_data_by_id('collection_option', item_id, collection_option)
            # set_call_data_by_id('spacial_conditions', item_id, spacial_conditions)
            set_data_by_id(item_id, "state", state)
            set_data_by_id(item_id, "quantity", quantity)
            set_call_data('next_stage', 'continue')


# החזרת הפריטים שמתאימים לחיפוש כאוביקטים


def send_ending_response():
    delete_call_data()
    return jsonify({}), 200  # החזר ריק הוא הסימן לסיום השירות


def delete_items_stage():
    """Function to delete a record"""
    phone_number = get_call_data("phone_number")
    if not phone_number:
        set_call_data('next_stage', 'finish_and_return')
        return
    send_delete_item_requests_stage(phone_number)


def send_delete_item_requests_stage(phone_number):
    last_id = get_call_data('last_id')
    data_list = get_data_list(phone_number)
    if not data_list:
        set_call_data('next_stage', 'finish_and_return_stage')
        return
    message = ""
    digits = request.json.get('digits')
    if digits and digits == "0":
        set_call_data('next_stage', 'start')
        return
    if last_id and digits and digits == "2":
        delete_data_by_id(last_id)
        message += "הרשומה נמחקה,"
    next_item = get_next_item(data_list, key=last_id)
    if not next_item:
        set_call_data('next_stage', 'finish_and_return_stage')
        return

    id = list(next_item.keys())[0]
    set_call_data('last_id', id)
    set_call_data('next_stage', 'delete_items')
    return jsonify({
        'message': message + f"בקשת למסור פריט {get_data_by_id('object_name', id)}, למחיקה הקש 2 למעבר לרשומה הבאה הקש 1",
        "number_of_digits": 1
    }), 200


def get_confirmation_massage():
    # שליפה של הנתונים שכבר נאספו
    username = get_call_data('username')
    object_name = get_call_data('object_name')
    description = get_call_data('Description')
    city_or_neighborhood = get_call_data('city_or_neighborhood')
    state = get_call_data('state')
    quantity = get_call_data('quantity')

    # בניית הודעת האישור
    confirmation_message = (
        f"להלן הנתונים שהוזנו:\n"
        f"שמך: {username}\n"
        f"שם הפריט: {object_name}\n"
        f"תיאור: {description}\n"
        f"מיקומי איסוף: {city_or_neighborhood}\n"
        f"מצב הפריט: {state}\n"
        f"כמות: {quantity}\n"
        "אם המידע נכון, הקש 1. אם יש לתקן פרטים, הקש 2."
    )
    return confirmation_message


def confirm_stage():
    set_call_data('next_stage',
                  'save_items' if request.json.get('digits') == "1" else 'product_registration_for_delivery')


def create_required_data_schema(base_schema, include_categories=True):
    """
    יוצר סכמה של נתונים לפי מבנה בסיסי ואם יש צורך, מוסיף קטגוריות.
    :param base_schema: מילון המכיל את הסכמה הבסיסית.
    :param include_categories: אם True, מוסיף קטגוריות לסכמה.
    :return: סכמה מעודכנת.
    """
    schema = base_schema.copy()  # יצירת עותק כדי למנוע שינוי של המבנה המקורי
    if include_categories:
        schema.update({value: 'bool' for value in CATEGORIES_SCHEMA.values()})
    return schema


"""Dictionary of stages"""
stages = {
    'start': {
        'object': {
            'message': lambda: 'אָנוּ שְׂמֵחִים לִרְאוֹת אוֹתְךָ בְּזִירַת מַעֲבִירִים יָדַיִם- הַזִּירָה שֶׁתְּאַפְשֵׁר לְךָ לִמְסֹר חֲפָצִים בָּהֶם אֵינְךָ מְעֻנְיַן עוֹד, אוֹ לִמְצֹא חֲפָצִים חֲדָשִׁים שֶׁבִּרְצוֹנְךָ לְקַבֵּל,, לְהַצָּעַת חֵפֶץ לִמְסִירָה וְרִשּׁוּם  לַמַּאֲגָר הַקֵּשׁ 1,  לְחִפּוּשׂ מוּצָר  הַקֵּשׁ 2, לִמְחִיקַת רִשּׁוּמִים קוֹדְמִים הַקֵּשׁ 3 ',
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': start_stage,
    }, 'finish_and_return': {
        'object': {
            'message': lambda: "הבקשה שלך הסתיימה. תוכל כעת לנתק את השיחה או לחזור לתפריט הראשי בהקשת 0",
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': finish_and_return_stage,
    },
    'product_registration_for_delivery': {
        'object': {
            'message': lambda: "כְּדֵי שֶׁנּוּכַל לְהַצִּיג אֶת הַחֵפֶץ שֶׁלְּךָ בְּבֵרוּר, הַזֵּן אֶת הַפְּרָטִים הַבָּאִים: קֹדֶם כֹּל, אָנָּא צַיֵּן בְּקוֹל בָּרוּר אֶת שִׁמְךָ כְּפִי שֶׁתִּרְצֶה שֶׁיִּפְנוּ אֵלֶיךָ. ציין גם את שֵׁם הַחֵפֶץ בִּקְצָרָה, כְּמוֹ לְדֻגְמָא, 'מַחְשֵׁב נַיָּד' אוֹ 'סַפָּה לַסָּלוֹן'. אַחַר כָּךְ, פָּרֵט קְצָת עַל הַחֵפֶץ, כּוֹלֵל מַצָּב וּמְאַפְיְנִים כְּמוֹ גֹּדֶל מָסָךְ, סוּג בַּד, שָׁנָה וְדֶגֶם. ",
            'required_data_schema': create_required_data_schema({
                'username': 'str',
                'object_name': 'str',
                'Description_of_the_unique_characteristics': 'str',
                'city_or_neighborhood': 'str'
            })},
        'action': product_registration_for_delivery_stage,
        'next_stage': 'state_of_item',
    },
    'location_collection_options_limitations': {
        'object': {
            'message': lambda: "אנא צַיֵּן גַּם אֶת מִקּוּם הַחֵפֶץ, לְמָשָׁל שֵׁם הָעִיר אוֹ אֵזוֹר כְּלָלִי, כְּדֵי שֶׁהַמִּתְעַנְיֵן יָבִין אֶת מֶרְחַק הָאִסּוּף. בְּנוֹסָף, צַיֵּן גַּם אֶפְשָׁרוּיוֹת לְאִסּוּף עַצְמִי אוֹ מִשְׁלוֹחַ. וְאִם יֵשׁ תְּנָאִים מְיֻחָדִים לְאִסּוּף, כְּמוֹ יָמִים אוֹ שָׁעוֹת מָעֳדָפִים אוֹ מִגְבָּלוֹת עַל הָאִסּוּף, צַיֵּן גַּם אוֹתָם.  ",
            'required_data_schema': {'city_or_neighborhood': 'str',
                                     'Possibility_of_self_collection_or_delivery': 'str',
                                     'Availability_for_collection_or_special_conditions_for_receiving_the_item': 'str'},
        },
        'action': location_collection_options_limitations_stage,
        'next_stage': 'state_of_item'
    },

    'state_of_item': {
        'object': {
            'message': lambda: "אָנָּא עֲזֹר לָנוּ לצַיֵּן אֶת מַצַּב הַחֵפֶץ: אִם הוּא חָדָשׁ הַקֵּשׁ 1, מְשֻׁמָּשׁ הַקֵּשׁ 2,  זָקוּק לְתִקּוּן הַקֵּשׁ 3 אִם הַכֹּל מַתְאִים לְךָ הַקֵּשׁ 4   ",
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': state_of_item_stage,
        'next_stage': 'quantity_selection',
    },
    'quantity_selection': {
        'object': {
            'message': lambda: "אִם בִּרְצוֹנְךָ לִמְסֹר יוֹתֵר מֵחֵפֶץ אֶחָד כָּזֶה, הַקֵּשׁ כַּמָּה חֲפָצִים כָּאֵלֶּה בִּרְצוֹנְךָ לִמְסֹר. אִם לֹא, הַקֵּשׁ 0",
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': quantity_selection_stage,
        'next_stage': 'confirm',
    },
    'save_and_continue': {
        'object': {
            'message': lambda: "רק רגע... אנחנו שומרים את פרטי החפץ במאגר...",

        },
        'action': save_and_continue_stage,
        'next_stage': 'save_items'
    }, 'save_items': {
        'object': {
            'message': lambda: "רק רגע... אנחנו עדיין שומרים את פרטי החפץ במאגר...",

        },
        'action': save_items_stage,
    },
    'confirm': {
        'object': {
            'message': get_confirmation_massage,
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': confirm_stage,
    },
    'continue': {
        'object': {
            'message': lambda: "אִם תִּרְצֶה לְהוֹסִיף חֵפֶץ נוֹסָף, תּוּכַל לְהַקִּישׁ כָּעֵת עַל 1, אוֹ, כְּדֵי לַחֲזֹר לַתַּפְרִיט הָרָאשִׁי הַקֵּשׁ כָּל סִפְרָהּ אַחֶרֶת",
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': finish_and_return_stage,
    },
    'finding_object_for_acceptance': {
        'object': {
            'message': lambda: 'בָּרוּךְ הַבָּא לְמַעֲרֶכֶת הַסִּנּוּן שֶׁלָּנוּ! כְּדֵי שֶׁנּוּכַל לִמְצֹא אֶת הַחֲפָצִים שֶׁהֲכִי מַתְאִימִים לָךְ אָנָּא אֱמֹר אֶת שֵׁם הַחֵפֶץ שֶׁבִּרְצוֹנְךָ לִמְצֹ וּמֵאֵיזֶה עִיר תִּרְצֶה לֶאֱסֹף אֶת הַחֵפֶץ ',
            'required_data_schema': create_required_data_schema({
                'object_name': 'str',
                'city_or_neighborhood': 'str'
            }, )
        },
        'action': finding_object_for_acceptance_stage,
        'next_stage': 'filter_item_by_state',
    },

    'filter_item_by_state': {
        'object': {
            'message': lambda: 'אָנָּא הַקֵּשׁ בְּאֵיזֶה מַצָּב תִּרְצֶה אֶת הַחֵפֶץ, : חָדָשׁ הַקֵּשׁ 1, מְשֻׁמָּשׁ הַקֵּשׁ 2 יָשָׁן הַקֵּשׁ 3. אִם הַכֹּל מַתְאִים לְךָ הַקֵּשׁ 4',
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': state_of_item_stage,
        'next_stage': 'search_items',
    },
    'search_items': {
        'object': {
            'message': lambda: 'אָנָּא הַמְתֵּן בִּזְמַן שֶׁאָנוּ מְחַפְּשִׂים לְךָ חֲפָצִים מַתְאִימִים',
        },
        'action': search_item_stage,
        'next_stage': 'notify_items'
    },
    'notify_items': {
        'object': {
            'message': get_found_items_message,
        },
        'action': notify_items_stage,
    },
    'handle_phone_request': {
        'object': {
            'message': handle_phone_request,
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': handle_phone_request_stage,

    },
    'no_items_found': {
        'object': {
            'message': lambda: 'מִצְטַעֲרִים, לֹא נִמְצְאוּ חֲפָצִים מַתְאִימִים. תּוּכַל לְהַקִּישׁ כָּעֵת 1 לְחִפּוּשׂ חָדָשׁ, 2 לַחֲזָרָה לְתַפְרִיט רָאשִׁי. ,תּוֹדָה וּלְהִתְרָאוֹת.',
        },
        'action': no_items_found_stage,
    }, 'delete_items': {
        'object': {
            'message': lambda: 'כעת יושמעו כל הפריטים שרצית למסור. למעבר לפריט הבא הקש 1, למחיקת פריט הקש 2, לסיום תוכל לנתק את השיחה או לחזור לתפריט הראשי בהקשת 0',
        },
        'action': delete_items_stage,
    }

}

"""Dictionary to save categories for AI"""
categories = {
    "Furniture": {
        "hebrew_name": "ריהוט",
        "items": [
            "ספות", "כורסאות", "שולחנות אוכל", "שולחנות עבודה", "שולחנות צד",
            "כיסאות משרד", "כיסאות אוכל", "כיסאות בר", "ארונות בגדים",
            "ארונות מטבח", "מיטות יחיד", "מיטות זוגיות", "מזרנים בגדלים שונים",
            "מדפים מעץ", "מדפי מתכת", "שידות לילה", "שידות מגירות",
            "מזנונים לסלון", "ארוניות אחסון", "פתרונות אחסון מתקפלים"
        ]
    },
    "Electronics": {
        "hebrew_name": "מכשירי חשמל ואלקטרוניקה",
        "items": [
            "מקררים", "מקפיאים", "תנורי אפייה", "כיריים גז", "כיריים חשמליים",
            "מיקרוגלים", "קומקומים חשמליים", "טוסטרים", "מכונות כביסה",
            "מייבשי כביסה", "טלוויזיות שטוחות", "טלוויזיות חכמות",
            "מחשבים ניידים", "מחשבים נייחים", "טאבלטים", "סמארטפונים",
            "מערכות שמע ביתיות", "רמקולים בלוטות'", "שואבי אבק רגילים",
            "שואבי אבק רובוטיים", "מאווררים", "מזגנים ניידים", "מזגנים קבועים"
        ]
    },
    "Children_and_Babies": {
        "hebrew_name": "ילדים ותינוקות",
        "items": [
            "עגלות תינוק", "עגלות תאומים", "כיסאות בטיחות לרכב",
            "כיסאות אוכל לתינוקות", "מיטות תינוק מעץ", "לול תינוקות",
            "עריסות", "צעצועים לפעוטות", "משחקי חשיבה", "ספרי ילדים",
            "בגדי תינוקות (בנים ובנות)", "מנשאים לתינוקות", "טרמפולינות לתינוקות",
            "מתקני פעילות לתינוקות", "מזרנים לפעוטות"
        ]
    },
    "Clothing_and_Footwear": {
        "hebrew_name": "ביגוד והנעלה",
        "items": [
            "חולצות נשים", "חולצות גברים", "חולצות ילדים",
            "מכנסיים לנשים", "מכנסיים לגברים", "מכנסיים לילדים",
            "שמלות", "חצאיות", "מעילים לנשים", "מעילים לגברים",
            "כובעים אופנתיים", "חגורות עור", "נעליים לנשים",
            "נעליים לגברים", "נעלי ספורט", "מגפיים", "סנדלים"
        ]
    },
    "Home_and_Garden": {
        "hebrew_name": "ציוד לבית ולגן",
        "items": [
            "מנורות שולחן", "מנורות קיר", "שנדלירים", "סירים",
            "מחבתות", "כוסות זכוכית", "צלחות קרמיקה", "סט סכו\"ם",
            "מצעים למיטה זוגית", "מצעים למיטת יחיד", "שמיכות פוך",
            "כריות שינה", "תמונות קיר", "אגרטלים", "פסלים לנוי",
            "שטיחים קטנים", "שטיחים גדולים", "מערכות ישיבה לגינה",
            "ערסלים", "ציוד לגינון", "מגזמות", "גרילים גז", "גרילים פחם"
        ]
    },
    "Books_and_Media": {
        "hebrew_name": "ספרים ומדיה",
        "items": [
            "ספרי לימוד", "רומנים", "ספרי מתח", "ספרי בישול",
            "מגזינים בעברית", "מגזינים באנגלית", "דיסקים מוזיקליים",
            "תקליטים ישנים", "משחקי מחשב", "משחקי קונסולה", "סרטי DVD",
            "סרטי Blu-ray"
        ]
    },
    "Transportation": {
        "hebrew_name": "תחבורה",
        "items": [
            "אופניים רגילים", "אופניים חשמליים", "קורקינטים רגילים",
            "קורקינטים חשמליים", "עגלה לקניות", "חלקי חילוף לאופניים",
            "קסדות אופניים", "מנעולים לאופניים", "כיסויי גשם לאופניים"
        ]
    },
    "Sports_and_Leisure": {
        "hebrew_name": "ציוד ספורט ופנאי",
        "items": [
            "משקולות", "מוטות משקולות", "מכונות כושר ביתיות",
            "מחצלות יוגה", "מזרני פילאטיס", "כדורי כדורגל",
            "כדורי כדורסל", "מחבטים לטניס", "אוהלים לקמפינג",
            "מזרנים מתנפחים", "תיקי טיולים", "אביזרי דיג"
        ]
    },
    "Pets": {
        "hebrew_name": "חיות מחמד",
        "items": [
            "מיטות לכלבים", "מיטות לחתולים", "כלובים לציפורים",
            "צעצועים לכלבים", "צעצועים לחתולים", "שקי מזון לכלבים",
            "שקי מזון לחתולים", "רצועות לכלבים", "קולרים לחתולים"
        ]
    },
    "DIY_and_Tools": {
        "hebrew_name": "עשה זאת בעצמך וכלי עבודה",
        "items": [
            "מברגים שטוחים", "מברגים פיליפס", "פטישים רגילים",
            "פטישי גומי", "מסורים ידניים", "מסורים חשמליים",
            "דבקים שקופים", "דבקי אפוקסי", "צבעי קיר",
            "צבעי עץ", "מברשות לצביעה", "מקדחות חשמליות",
            "מברגות חשמליות", "סט כלי עבודה רב שימושיים"
        ]
    },
    "Business_and_Office": {
        "hebrew_name": "מוצרים לעסקים ומשרד",
        "items": [
            "שולחנות עבודה",
            "כסאות משרדיים",
            "ציוד משרדי (מדפסות, סורקים)",
            "קלסרים וארגזי אחסון",
            "מדפים",
            "מנורות שולחן",
            "לוחות מודעות",
            "מחשבים ניידים ומסכים"
        ]
    },
    "Collectibles_and_Art": {
        "hebrew_name": "פריטי אספנות ואמנות",
        "items": [
            "פריטי וינטג'",
            "ציורים ותמונות",
            "מטבעות ובולים",
            "כלי נגינה",
            "פסלים",
            "ספרי אמנות נדירים",
            "פריטי קרמיקה",
            "פוסטרים"
        ]
    },
    "Health_and_Personal_Care": {
        "hebrew_name": "בריאות וטיפוח אישי",
        "items": [
            "מכשירים רפואיים",
            "אביזרי טיפוח (מייבשי שיער, מכונות גילוח)",
            "משקלות אדם",
            "ציוד עזרה ראשונה",
            "אביזרי כושר ביתיים",
            "קרמים ומוצרי טיפוח",
            "מסכות פנים"
        ]
    },
    "Food_and_Beverages": {
        "hebrew_name": "מזון ומשקאות",
        "items": [
            "מוצרי מזון מיוחדים",
            "יינות ומשקאות אלכוהוליים",
            "ציוד בישול ואפייה",
            "כלי שולחן יוקרתיים",
            "שוקולדים וממתקים מיוחדים"
        ]
    },
    "Jewelry_and_Accessories": {
        "hebrew_name": "תכשיטים ואקססוריז",
        "items": [
            "שעונים",
            "שרשראות",
            "טבעות",
            "צמידים",
            "משקפי שמש",
            "עגילים",
            "אביזרי שיער"
        ]
    },
    "Outdoor_Equipment": {
        "hebrew_name": "ציוד חוץ",
        "items": [
            "גרילים",
            "ציוד מחנאות",
            "כיסאות חוץ",
            "מערכות ישיבה לגינה",
            "בריכות מתנפחות",
            "צעצועים לגינה",
            "ציוד ספורט חוץ"
        ]
    },
    "Education_and_Learning": {
        "hebrew_name": "חינוך ולמידה",
        "items": [
            "ערכות לימוד",
            "צעצועי חינוך",
            "לוחות כתיבה",
            "אמצעי הוראה",
            "ספרי לימוד",
            "משחקי חשיבה",
            "מדריכים דיגיטליים"
        ]
    },
    "Luxury_Goods": {
        "hebrew_name": "מוצרים יוקרתיים",
        "items": [
            "תיקים יוקרתיים",
            "מותגים מובילים של שעונים ותכשיטים",
            "פרטי אמנות יוקרתיים",
            "נעליים מעוצבות",
            "מכוניות יוקרה מיניאטוריות",
            "בקבוקי יין נדירים"
        ]
    }, "Kitchen": {
        "hebrew_name": "מטבח",
        "items": [
            "סירים ומחבתות",
            "ציוד אפייה",
            "מיקסרים", "מעבדי מזון", "כלי אוכל", "צלחות", "מזלגות", "כפיות", "קערות", "סכינים"
                                                                                      "מקררים",
            "תנורים",
            "מערכות אחסון למטבח",
            "קנקנים ומחממים מים"
        ]
    }
    , "Gardening": {
        "hebrew_name": "גינון",
        "items": [
            "תערובות אדמה",
            "דשנים",
            "כלי עבודה לגינה",
            "שקיות זרעים",
            "עציצים"
        ]
    }
    , "Gardening": {
        "hebrew_name": "גינון",
        "items": [
            "תערובות אדמה",
            "דשנים",
            "כלי עבודה לגינה",
            "שקיות זרעים",
            "עציצים"
        ]
    },
    "Travel_and_Tourism": {
        "hebrew_name": "תיירות ונסיעות",
        "items": [
            "מזוודות",
            "תיקים ותרמילים",
            "ציוד לטיולים (מפות, פנסים)",
            "מדריכי טיולים",
            "ביטוחי נסיעות"
        ]
    }
    ,
    "General": {
        "hebrew_name": "כללי",
        "items": ["אחר"]
    }
}


        `;

    
const codeBlockStyle = {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    };
  return (
    <div dir="ltr" style={codeBlockStyle}>
  <SyntaxHighlighter language="javascript" style={dark} customStyle={{ backgroundColor: '#000', color: '#fff' }} >
  {code}
   </SyntaxHighlighter></div>
);
};

export default FreeItemsExample;
