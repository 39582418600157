import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const TrempExample = () => {
        const code = `
from flask import Blueprint
from flask import Flask, jsonify, request
import json
import uuid
import threading
from datetime import datetime
import traceback

from database_functions import (
    set_data,
    set_data_by_id,
    get_data,
    get_data_by_id,
    get_data_list,
    set_call_data,
    set_call_data_by_id,
    get_call_data,
    get_call_data_by_id,
    get_call_data_list,
    delete_call_data,
    delete_data_by_key_and_id,
    delete_data_by_key,
    delete_data_by_id,
)

from utils import (
    TemporaryRequest,
    get_analysis_response,
    wait_for_analysis_response,
    get_similarity_results,
    get_similarity_rate,
    get_next_item,
    normalize_phone_number
)

tremp_api = Blueprint('tremp_api', __name__)

# הקוד פותח על ידי ריקי שפר
manager_phone = "089744284"  # הכנס את הטלפון שלך, טלפון  זה ישמש לניהול השירות
email = "r8524409@gmail.com"  # הכנס את האימייל שלך
base_url = "........."

# חלק הרישום של השירות
service_data = {
    'manager_phone': manager_phone,
    'email': email,
    'connection_url': base_url + "tremp_service",
    'service_name': 'מַעֲרֶכֶת לְחִפּוּשׂ טְרֶמְפִּים',
    'brief_description': 'בְּרוּכִים הַבָּאִים לְמַעֲרֶכֶת הַטְּרֶמְפִּים! שֵׁרוּת זֶה מְאַפְשֵׁר לַמִּשְׁתַּמְּשִׁים לְחַפֵּשׂ וּלְהַצִּיעַ טְרֶמְפִּים, וְלִמְצֹא שֻׁתָּפִים לִנְסִיעוֹת בְּקַלּוּת.',
    'message': 'בָּחַר אֶת הַשְּׁלוּחָה הַמְּבֻקֶּשֶׁת: חִפּוּשׂ טְרֶמְפִּים, לְחַץ 1 | הַצָּעַת טְרֵמְפּ כְּנַהָג, לְחַץ 2 |לִמְחִיקַת בַּקָּשָׁה לְחַץ 3',
    'audio_url': '',
    'long_explanation': 'בהַמַּעֲרֶכֶת לְנִהוּל טְרֶמְפִּים מְסַפֶּקֶת פִּתְרוֹן מָלֵא לְנִהוּל וְהַצָּעַת טְרֶמְפִּים. הִיא מְאַפְשֶׁרֶת לִנְהָגִים לְהַצִּיעַ טְרֶמְפִּים וְלִשְׁמֹר אֶת פִּרְטֵיהֶם בְּמַסַּד נְתוּנִים, לְצַד אֶפְשָׁרוּת לַנּוֹסְעִים לְחַפֵּשׂ טְרֶמְפִּים זְמִינִים, לַעֲבֹר בֵּין אֶפְשָׁרוּיוֹת וּלְהִתְקַשֵּׁר עִם הַנֶּהָגִים יְשִׁירוֹת. הַמַּעֲרֶכֶת תּוֹמֶכֶת גַּם בְּנִהוּל וּמְחִיקַת טְרֶמְפִּים לְפִי מִסְפַּר טֵלֵפוֹן, וּמְאַפְשֶׁרֶת מְחִיקַת טְרֶמְפִּים בְּצוּרָה יְדִידוּתִית וּמְמֻקֶּדֶת. כָּל הַפְּעֻלּוֹת מְבֻצָּעוֹת בְּאֹפֶן אִינְטֶרְאַקְטִיבִי, תּוֹךְ הַתְאָמָה מְלֵאָה לְצָרְכֵי הַמִּשְׁתַּמֵּשׁ.',
    'required_data_schema': {},  # לא דרושים נתונים מיוחדים
    'number_of_digits': 1,  # מספר ספרות דרושות
    'phone_number_required': True,  # דרוש טלפון
    'email_required': False,  # דואר אלקטרוני לא דרוש
    'credit_card_required': False,  # כרטיס אשראי לא דרוש
    'system_payments': False,  # אין תשלומים במערכת
    'external_payments': False,  # אין תשלומים חיצוניים
    'entry_amount_to_be_paid': 0,  # אין סכום לפתיחה
    'referral_phone': '',  # אין טלפון הפניה
    'analysis_delay': 0  # אין עיכוב בניתוח
}


@tremp_api.route('/tremp_service', methods=['POST'])
def tremp_service():
    target = request.json.get('target')
    if target == 'registration':
        return jsonify(service_data), 200
    elif target == 'service_processing':
        try:
            stage = get_call_data('next_stage')
            if not stage:
                stage = 'welcome'
                phone_number = request.json.get('phone_number')
                if phone_number:
                    set_call_data("phone_number", phone_number)
                else:
                    return send_ending_response()
            return do_stage(stage)
        except Exception as e:
            print(f"Error: {e}")
            traceback.print_exc()
            return send_ending_response()


# זו הפונקציה המעודכנת:
def do_stage(stage):
    # קבלת השלב מהמילון stages
    stage_data = stages.get(stage)
    if not stage_data:
        return send_ending_response()  # אם השלב לא נמצא, נחזיר תשובה

    next_stage = stage_data.get('next_stage')
    set_call_data('next_stage', next_stage)
    # הפעלת הפעולה של השלב
    stage_data['action']()

    # קבלת השלב הבא מהמידע הגולמי או מהקריאה ל- get_call_data
    next_stage = get_call_data('next_stage')

    # אם אין next_stage, נסיים את הפונקציה
    if not next_stage:
        return send_ending_response()

    # בניית התשובה עם הערכים המתאימים
    next_stage_data = stages.get(next_stage)
    if not next_stage_data:
        return send_ending_response()

    response = {}

    # בדוק אם כל מפתח קיים לפני הוספתו ל-response
    if 'message' in next_stage_data['object'] and callable(next_stage_data['object']['message']):
        response['message'] = next_stage_data['object']['message']()
    if 'number_of_digits' in next_stage_data['object']:
        response['number_of_digits'] = next_stage_data['object']['number_of_digits']
    if 'required_data_schema' in next_stage_data['object']:
        response['required_data_schema'] = next_stage_data['object']['required_data_schema']

    return jsonify(response), 200


def main_menu_stage():
    digits = request.json.get('digits')
    if digits == '1':
        set_call_data('next_stage', 'search_rides')
    elif digits == '2':
        set_call_data('next_stage', 'offer_ride')
    elif digits == '3':
        delete_call_data()  # לכתבו פוקנציה שמטפלת במחיקת נותנים מהמסד לפי מהפסר טלפוןו
    else:
        set_call_data('next_stage', 'welcome')


def search_rides_stage():
    set_call_data_by_id("analysis_response_url", "name_Destination", request.json.get('analysis_response_url'))


def search_rides_additional_data():
    set_call_data_by_id("analysis_response_url", "Requested_neighborhood_Departure_city",
                        request.json.get('analysis_response_url'))


def convert_to_time(four_digits):
    """
    ממירה מספר בן 4 ספרות לפורמט של שעה (HH:MM).
    :param four_digits: int או str - מספר בן 4 ספרות (לדוגמה, 1111)
    :return: str - שעה בפורמט HH:MM
    """
    # Ensure input is a string for slicing
    four_digits = str(four_digits)

    # Validate input length
    if len(four_digits) != 4 or not four_digits.isdigit():
        raise ValueError("Input must be a 4-digit number.")

    # Extract hours and minutes
    hours = four_digits[:2]
    minutes = four_digits[2:]

    # Return in HH:MM format
    return f"{hours}:{minutes}"


def search_rides_additional_data_time():
    digits = request.json.get('digits')
    # דוגמה לשימוש
    time = convert_to_time(digits)
    if digits:
        set_call_data("time_to_go", time)
    else:
        set_call_data("time_to_go", 0)
    set_call_data_by_id("analysis_response_url", "place", request.json.get('analysis_response_url'))
    temp_request = TemporaryRequest(request)
    thread = threading.Thread(target=save_ride_search_responses_by_thread, args=(temp_request,))
    thread.start()


def search_rides_additional_data_passengers():
    digits = request.json.get('digits')

    if digits:
        set_call_data("time_to_go", digits)
    else:
        set_call_data("time_to_go", 0)


def offer_ride_stage():
    set_call_data_by_id("analysis_response_url", "Search_details", request.json.get('analysis_response_url'))


def request_ride_stage():
    set_call_data_by_id("analysis_response_url", "Search_details", request.json.get('analysis_response_url'))


# שיאבת הנתנים המתנה קבלת התנותנים
def save_ride_search_responses_by_thread(temp_request):
    response_urls_list = get_call_data_list("analysis_response_url", request=temp_request)
    response_urls_dict = {}
    # איסוף נתוני כתובות URL
    for response in response_urls_list:
        response_urls_dict.update(response)

    # אם נתון מסוים חסר, נמתין לתשובת הניתוח הרלוונטית
    name_and_dest_analysis = wait_for_analysis_response(response_urls_dict.get("name_Destination"))
    city_and_neighborhood_analysis = wait_for_analysis_response(
        response_urls_dict.get("Requested_neighborhood_Departure_city"))
    if name_and_dest_analysis and city_and_neighborhood_analysis:
        set_call_data('name', name_and_dest_analysis.get('analysis_response', {}).get('required_data', {}).get('name'),
                      request=temp_request)
        set_call_data('Departure_city',
                      city_and_neighborhood_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          'Departure_city'), request=temp_request)
        set_call_data('Requested_neighborhood',
                      city_and_neighborhood_analysis.get('analysis_response', {}).get('required_data', {}).get(
                          'Requested_neighborhood'), request=temp_request)
        set_call_data('Destination',
                      name_and_dest_analysis.get('analysis_response', {}).get('required_data', {}).get('Destination'),
                      request=temp_request)
        # סימון שהתשובות נשמרו
        set_call_data("analysis_responses_saved", "1", request=temp_request)


# שליפת נתונים שנשמרו
def fetch_saved_data():
    return {
        "phone_number": get_call_data("phone_number"),
        "name": get_call_data("name"),
        "Destination": get_call_data("Destination"),
        "time_to_go": get_call_data("time_to_go"),
        "Departure_city": get_call_data("Departure_city"),
        "Requested_neighborhood": get_call_data("Requested_neighborhood"),
    }


def get_similarity_results_by_keys(data_list, object_to_compare, keys_to_compare, id_key, similarity_threshold=0.6):
    """
    Calculate the similarity between an object and a list of objects based on specified keys.

    This function compares the values of specified keys in 'object_to_compare' with the corresponding values
    in each object within 'data_list'. It calculates the similarity for each key and averages the similarities
    for each object. If the average similarity is above the 'similarity_threshold', the object is included in
    the results, sorted by similarity in descending order.

    Parameters:
    -----------
    data_list : list of dict
        A list of dictionaries where each dictionary represents an object to compare.

    object_to_compare : dict
        A dictionary representing the object to compare against the objects in 'data_list'.

    keys_to_compare : list of str
        A list of keys to compare between 'object_to_compare' and each object in 'data_list'.

    id_key : str
        The key used to uniquely identify each object in 'data_list'.

    similarity_threshold : float, optional (default is 0.6)
        The minimum average similarity required for an object to be included in the results.

    Returns:
    --------
    list of dict
        A list of dictionaries representing the objects from 'data_list' that have an average similarity
        above the 'similarity_threshold', sorted by similarity in descending order. Each dictionary includes
        an additional key 'similarity' representing the average similarity.

    Example:
    --------

    """
    results = []
    for key in keys_to_compare:
        data_list_key = []
        for data_object in data_list:
            data_list_key.append({data_object.get(id_key): str(data_object.get(key))})

        results_key = get_similarity_results(data_list_key, object_to_compare.get(key), similarity_threshold)
        results.append(results_key)

    result = []
    # for each id, calculate the average, and if it is above the similarity_threshold, add it to the results, in order from the high
    for data_object in data_list:
        id = data_object.get(id_key)
        similarity_sum = 0
        for results_key in results:
            for result_key in results_key:
                if result_key.get('id') == id:
                    similarity_sum += result_key.get('similarity')
                    break
        similarity_avg = similarity_sum / len(keys_to_compare)
        if similarity_avg >= similarity_threshold:
            # add the object to result with the average similarity
            result.append({**data_object, 'similarity': similarity_avg})

    # sort the result by the similarity
    return sorted(result, key=lambda x: x['similarity'], reverse=True)


# בדיקת שדות חסרים
def find_empty_fields(fields):
    return [field for field, value in fields.items() if value in [None, ""]]


# טיפול בשדות חסרים
def handle_missing_fields(phone_number):
    delete_call_data()
    set_call_data("phone_number", phone_number)
    set_call_data("next_stage", "welcome")


# סינון התאמות
def filter_matches(data_list, tremp_id, phone_number, Departure_city, Requested_neighborhood, Destination, time_to_go):
    # סינון לפי עיר יעד
    if Destination != "all":
        data_list = [
            data for data in data_list
            if (
                    get_data_by_id("Destination", data["id"]) == "all"
                    or get_similarity_rate(get_data_by_id("Destination", data["id"]), Destination) > 0.6
            )
        ]

    # סינון לפי עיר מוצא (רק אחרי עיר יעד)
    if Departure_city != "all":
        data_list = [
            data for data in data_list
            if (
                    get_data_by_id("Departure_city", data["id"]) == "all"
                    or get_similarity_rate(get_data_by_id("Departure_city", data["id"]), Departure_city) > 0.6
            )
        ]

    # סינון לפי שכונה
    if Requested_neighborhood != "all":
        data_list = [
            data for data in data_list
            if (
                    get_data_by_id("Requested_neighborhood", data["id"]) == "all"
                    or get_similarity_rate(get_data_by_id("Requested_neighborhood", data["id"]),
                                           Requested_neighborhood) > 0.6
            )
        ]

    return data_list


def get_common_ids(data_list1, data_list2):
    """
    פונקציה שמוצאת נתונים משותפים עם אותו ID בין שני מערכים.

    פרמטרים:
    ----------
    data_list1 : list of dict
        רשימת נתונים ראשונה.

    data_list2 : list of dict
        רשימת נתונים שנייה.

    החזרה:
    -------
    list of dict
        רשימה של נתונים משותפים עם אותם IDs.
    """
    # יצירת סט של IDs מהרשימה הראשונה
    ids1 = {item['id'] for item in data_list1}

    # סינון הנתונים מהרשימה השנייה לפי ה-IDs המשותפים
    common_data = [item for item in data_list2 if item['id'] in ids1]

    return common_data


# פונקציה לשמירת הנתונים
def save_tremp_data(phone_number, name, Destination, time_to_go,
                    Departure_city, Requested_neighborhood):
    # יצירת מזהה ייחודי
    tremp_id = str(uuid.uuid4())

    # שמירת הנתונים לפי המזהה הייחודי
    set_data_by_id(tremp_id, "tremp_id", tremp_id)
    set_data_by_id("tremp", tremp_id, tremp_id)
    set_data_by_id("phone_number", tremp_id, phone_number)  # שמירה לפי טלפון בשביל המחיקה
    set_data_by_id(tremp_id, "tremp_phone_number", phone_number)  # מה נכון
    # שמירה בשבילtremp_id  בשביל שנוכל לחלץ את התנים בצורה קלה
    set_data_by_id(tremp_id, "phone_number", phone_number)
    set_data_by_id(tremp_id, "name", name)

    set_data_by_id(tremp_id, "Destination", Destination)

    set_data_by_id(tremp_id, "time_to_go", time_to_go)

    set_data_by_id(tremp_id, "Departure_city", Departure_city)

    set_data_by_id(tremp_id, "Requested_neighborhood", Requested_neighborhood)

    return tremp_id


# פונקציה ליצירת אובייקט נתונים
def create_tremp_data(phone_number, name, Destination, time_to_go,
                      Departure_city, Requested_neighborhood):
    # יצירת מזהה ייחודי
    tremp_id = str(uuid.uuid4())

    # יצירת אובייקט נתונים
    trip_data = {
        "tremp_id": tremp_id,
        "phone_number": phone_number,
        "name": name,
        "Destination": Destination,
        "time_to_go": time_to_go,
        "Departure_city": Departure_city,
        "Requested_neighborhood": Requested_neighborhood,
    }

    return trip_data


# פונקציה לעיבוד כל פריט
def process_tremp(obj):
    # שליפת ה-ID
    tremp_id = list(obj.keys())[0]
    # קבלת האובייקט המלא מהפונקציה
    tremp_data = get_data_list(tremp_id)
    # במקום להחזיר מערך, מחזירים את האובייקט ישירות
    return tremp_data  # מחזירה את האובייקט המלא


def merge_tremp_data(tremp_data_list):
    result = []

    for tremp in tremp_data_list:
        # מיזוג כל המילונים בפריט לאובייקט אחד
        combined_data = {}
        for data in tremp:
            combined_data.update(data)

        # הוספת האובייקט המאוחד לתוצאה
        result.append(combined_data)

    return result


#  הפונקציה הראשית שאחרית על סינון ושמירת הנתנים במסד
def find_matches_stage():
    if not get_call_data("analysis_responses_saved"):
        set_call_data("next_stage", "matching_results")
        return

    fields = fetch_saved_data()
    empty_fields = find_empty_fields(fields)

    if empty_fields:
        return handle_missing_fields(fields["phone_number"])

    tremp_id_ob = create_tremp_data(
        fields["phone_number"],
        fields["name"],
        fields["Destination"],
        fields["time_to_go"],
        fields["Departure_city"],
        fields["Requested_neighborhood"]
    )
    tremp_list = get_data_list("tremp")

    # שימוש ב-map
    # כעת, נעשה שימוש ב-map כך שיתקבל מערך של אובייקטים
    tremp_data_list = list(map(process_tremp, tremp_list))
    merged_data = merge_tremp_data(tremp_data_list)

    # רשימת מפתחות להשוואה
    keys_to_compare = ["Departure_city",
                       "Requested_neighborhood", "Destination", "time_to_go"]

    map_data_list = get_similarity_results_by_keys(merged_data, tremp_id_ob, keys_to_compare, "tremp_id")

    for index, data in enumerate(map_data_list):
        tremp_id = data.get("tremp_id")  # שליפת ה-ID של הטרמפ
        set_call_data_by_id("relevant_rides_similarity", index, tremp_id)  # שמירת כל המידע תחת ה-ID

    get_call_data_by_id("relevant_rides_similarity", 0)

    set_call_data("current_index", 0)  # לשמור איפה אני נמצאת בלולאה
    set_call_data("count_tremp", len(map_data_list))  # לכמה יש לי סהכ הכל


# פונקציה שמנהלת בכל תהליך השמעת הטרמפים
def notify_match_stage():
    digits = request.json.get('digits')

    # שליפת ה-index הנוכחי
    index = int(get_call_data('current_index'))
    count_tremp = int(get_call_data("count_tremp"))
    # שליפת כמות הטרמפים

    # טיפול במקרה שאין טרמפים מתאימים
    if index == count_tremp - 1:
        set_call_data("next_stage", "no_more_tremps")
        return

    relevant_ride = get_call_data_by_id('relevant_rides_similarity', index)

    # טיפול במעבר בין שלבים
    if digits == "1":  # הצגת מספר טלפון
        set_call_data('current_index', index + 1)
        set_call_data("next_stage", "notify_match")
        return
    elif digits == "2":  # מעבר לטרמפ הבא
        set_call_data("next_stage", "handle_phone_request")
    else:
        set_call_data("next_stage", "no_more_tremps")


# פונקציה לטיפול במקרה שאין טרמפים מתאמים
def handle_no_rides():
    """מנהל את המקרה שבו אין טרמפים מתאימים."""
    return (
        f"לֹא נִמְצְאוּ טְרַמְפִּים מַתְאִימִים. מוזמן להתקשר יותר מאוחר ולבדוק שוב, תודה!"
    )


# פונקציה שואמרת שאין יותר טרמפים מתאימים
def handle_no_more_rides():
    """מנהל את המקרה שבו אין יותר טרמפים להציג."""
    message = "לֹא נִמְצְאוּ יוֹתֵר טְרַמְפִּים מַתְאִימִים.מוזמן להתקשר יותר מאוחר ולבדוק שוב, תודה!"
    set_call_data('next_stage', 'ending')  # להוסיך את זה שבעמם מחוק ממהסד את הבקשה
    return jsonify({'message': message}), 200


# מטפל בבקשת מספר טלפון
# ולשאול אם כן אני צריכה בעצם לטפל בלהוריד את כמות הנוסעים שיש עכשיו או למחוק את הבקשות
def handle_phone_request():
    """מטפל בבקשת מספר טלפון."""
    index = get_call_data('current_index')  # מזהה הטרמפ האחרון שהוצג
    relevant_ride = get_call_data_by_id('relevant_rides_similarity', index)  # רשימת הטרמפים המתאימים
    relevant_ride_list = get_data_list(relevant_ride)
    name = relevant_ride_list[3].get('name', 'לא צוין שם')
    phone_number = relevant_ride_list[4].get('phone_number', 'לא נשמר טלפון')
    phone_number = normalize_phone_number(phone_number)

    return (
        f"נִבְחַר טְּרַמְפּ: שֵׁם הַנָּהָג: {name}. מִסְפַּר הַטֶּלֶפוֹן: {phone_number}."
    )


# מחזיר פרטים על טרמפ לפי מזהה
def get_ride_details(ride_id):
    """מחזיר פרטים על טרמפ לפי מזהה."""
    return {
        'Departure_city': get_data_by_id('Departure_city', ride_id),
        'Destination': get_data_by_id('Destination', ride_id),
        'time_to_go': get_data_by_id('time_to_go', ride_id),
        'name': get_data_by_id('name', ride_id)
    }


def generate_ride_message():
    """יוצר הודעה מבוססת פרטי טרמפ."""

    # שליפת ה-ID האחרון שהוצג
    index = get_call_data('current_index')

    # שליפת המידע הרלוונטי על הטרמפ לפי ה-ID
    relevant_ride = get_call_data_by_id('relevant_rides_similarity', index)
    relevant_ride_list = get_data_list(relevant_ride)

    # Assuming relevant_ride is a list of dictionaries
    if relevant_ride_list and isinstance(relevant_ride_list, list):
        departure_city = relevant_ride_list[0].get('Departure_city', 'לא צוין עיר יציאה')
        destination = relevant_ride_list[1].get('Destination', 'לא צוין יעד')
        name = relevant_ride_list[3].get('name', 'לא צוין שם')
        time_to_go = relevant_ride_list[5].get('time_to_go', 'לא צוין זמן')

        ride_message = (
            f"נִמְצָא טְרֶמְפּ:"
            f"שֵׁם הַנַּהַג: {name}"
            f"מָקוֹם הַיְּצִיאָה: {departure_city}"
            f"עִיר הַיַּעַד: {destination}"
            f"שָׁעָה: {time_to_go}"
            f"לִבְחִירָה, לְחַץ 1 לְמַעְבָּר לַטְרַמְפּ הַבָּא, אוֹ 2 לְקַבָּלַת מִסְפַּר טֶלֶפוֹן."
        )
    else:
        ride_message = "לא נמצאו טרמפים מתאימים. לחץ 0 בשביל יציאה."
    return ride_message


def do_nothing():
    pass


def confirm_stage():
    if not get_call_data("analysis_responses_saved"):
        set_call_data("next_stage", "confirmation")
        return
    fields = fetch_saved_data()

    # קריאה לפונקציה לשמירת הנתונים
    tremp_id = save_tremp_data(
        fields["phone_number"],
        fields["name"],
        fields["Destination"],
        fields["time_to_go"],
        fields["Departure_city"],
        fields["Requested_neighborhood"]
    )
    set_call_data("next_stage", "succsess")


def send_ending_response():
    delete_call_data()  # מחיקת נתוני השיחה מהמערכת
    return jsonify({}), 200  # החזר ריק המסמל סיום


def get_confirmation_massage():
    # שליפה של הנתונים שכבר נאספו

    phone_number = get_call_data("phone_number")
    name = get_call_data("name")
    Destination = get_call_data("Destination")
    time_to_go = get_call_data("time_to_go")
    Departure_city = get_call_data("Departure_city")
    Requested_neighborhood = get_call_data("Requested_neighborhood")

    # בניית הודעת האישור
    confirmation_message = (
        f"להלן הנתונים שהוזנו:\n"
        f"שמך: {name}\n"
        f"עיר יציאה: {Departure_city}\n"
        f"שכונת יציאה איסוף: {Requested_neighborhood}\n"
        f"עיר יעד: {Destination}\n"
        f"שעת יציאה: {time_to_go}\n"
        "אם המידע נכון, הקש 1. אם יש לתקן פרטים, הקש 2."
    )
    return confirmation_message


def Confirm_data_step():
    set_call_data('next_stage',
                  'notify_match' if request.json.get('digits') == "1" else 'welcome')


stages = {
    'welcome': {
        'object': {
            'message': lambda: 'בָּרוּךְ הַבָּא לְמַעֲרֶכֶת חִפּוּשׂ הַטְּרֶמְפִּים! בְּחַר אֶת הַפְּעוּלָה הָרְצוּיָה:  לְחִפּוּשׂ טְרֶמְפּ לְחַץ 1: הַצָּעַת טְרֶמְפּ כְּנָהָג לְחַץ 2: מְחִיקַת טְרֶמְפּ לְחַץ 3: ',
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': main_menu_stage,
    },
    'search_rides': {
        'object': {
            'message': lambda: 'אָנָּא צִיֵּן אֶת שִׁמְךָ הַמָּלֵא ועִיר הַיַּעַד.',
            'required_data_schema': {
                'name': 'str',  # שם
                'Destination': 'str',  # עיר יעד
            }
        },
        'action': search_rides_stage,  # פעולה שמטפלת בקבלת הנתונים
        'next_stage': 'search_rides_additional_data'
    },
    'search_rides_additional_data': {
        'object': {
            'message': lambda: 'אָנָּא הוֹסֵף אֶת עִיר הַיְּצִיאָה, וּלְאַחַר מִכֵּן אֶת הַשְּׁכוּנָה הַמְּבֻקֶּשֶׁת.',
            'required_data_schema': {
                'Departure_city': 'str',  # עיר יציאה
                'Requested_neighborhood': 'str',  # שכונה
            }
        },
        'action': search_rides_additional_data,  # פעולה שמטפלת בקבלת הנתונים
        'next_stage': 'search_rides_additional_data_time'
    },
    # קלט יום בשבוע או היום
    'search_rides_additional_data_time': {
        'object': {
            'message': lambda: 'אָנָּא הקש אֶת הַשָּׁעָה הַמְּתֻכְנֶנֶת לִיצִיאָה (בְּפוֹרְמָט שֶׁל אַרְבַּע סְפָרוֹת).',
            'number_of_digits': 4,
            'required_data_schema': {
            }
        },
        'action': search_rides_additional_data_time,  # פעולה שמטפלת בקבלת הנתונים
        'next_stage': 'matching_results'
    },
    'offer_ride': {
        'object': {
            'message': lambda: 'אָנָּא צִיֵּן אֶת שְׁמֵךְ הַמָּלֵא וְאֶת הַיַּעַד. ',
            'required_data_schema': {
                'name': 'str',  # שם
                'Destination': 'str'  # יעד
            }
        },
        'action': search_rides_stage,  # פעולה לקליטת נתונים ראשוניים
        'next_stage': 'offer_ride_additional_data'
    },
    'offer_ride_additional_data': {
        'object': {
            'message': lambda: 'אָנָּא הוֹסֵף אֶת עִיר הַיְּצִיאָה, וּלְאַחַר מִכֵּן אֶת הַשְּׁכוּנָה הַמְּבֻקֶּשֶׁת.',
            'required_data_schema': {
                'Departure_city': 'str',  # עיר יציאה
                'Requested_neighborhood': 'str',  # שכונה
            }
        },
        'action': search_rides_additional_data,  # פעולה לקליטת נתונים ראשוניים
        'next_stage': 'offer_ride_additional_data_time'  # מעבר לשלב הבא
    },
    # קלט של יום טרמפ קבוע יום בשבוע או היום
    'offer_ride_additional_data_time': {
        'object': {
            'message': lambda: 'אָנָּא צִיֵּן אֶת הַשָּׁעָה הַמְּתֻכְנֶנֶת לִיצִיאָה (בְּפוֹרְמָט שֶׁל אַרְבַּע סְפָרוֹת).',
            'number_of_digits': 4,
            'required_data_schema': {
            }
        },
        'action': search_rides_additional_data_time,  # פעולה לקליטת נתונים ראשוניים
        'next_stage': 'confirmation'
    },
    'matching_results': {
        'object': {
            'message': lambda: 'אֲנוּ מְבַצְּעִים חִפּוּשׂ עֲבוּר הַתַּאֲמוּת, אֲנָא הַמִּתֵּן...'
        },
        'action': find_matches_stage,
        'next_stage': 'confirm'  # הוספנו אפשרות לעבור לתוצאה הבאה אחרי שלא נמצא תוצאה מתאימה
    },
    'confirm': {
        'object': {
            'message': get_confirmation_massage,
            'number_of_digits': 1,
            'required_data_schema': {}
        },
        'action': Confirm_data_step,
    },
    'notify_match': {
        'object': {
            'message': generate_ride_message,
            'number_of_digits': 1,
        },
        'action': notify_match_stage,
        'next_stage': 'welcome'
    },
    'no_more_tremps': {
        'object': {
            'message': handle_no_rides,
        },
        'action': do_nothing,
        'next_stage': 'welcome'
    },
    'handle_phone_request': {
        'object': {
            'message': handle_phone_request,
        },
        'action': do_nothing,
        'next_stage': 'welcome'
    },
    'confirmation': {
        'object': {
            'message': lambda: 'אֲנָא הַמִּתֵּן, הַנַּתּוּנִים נִשְׁמָרִים.',
        },
        'action': confirm_stage,
    },

    "succsess": {
        'object': {
            'message': lambda: 'הַבַּקָּשָׁה נִשְׁלָחָה בַּהֲצָלְחָה, תּוֹדָה שֶׁהִשְׁתַּמַּשְׁתְּ בַּעֲזרן מַעֲרֶכֶת חִיפוּשׂ טְרֶמְפִּים.'
        },
        'action': do_nothing,
        'next_stage': 'welcome'
    }
}

        

        `;

    
const codeBlockStyle = {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    };
  return (
    <div dir="ltr" style={codeBlockStyle}>
  <SyntaxHighlighter language="javascript" style={dark} customStyle={{ backgroundColor: '#000', color: '#fff' }} >
  {code}
   </SyntaxHighlighter></div>
);
};

export default TrempExample;
